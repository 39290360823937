import React from "react";

const XIcon = ({
	width = 24,
	height = 24,
	color = "currentColor",
	className,
}) =>
{
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			color={color}
			className={className}
		>
			<path
				d="M11.1889 10.0115L15.5997 5.60071C15.6793 5.52384 15.7428 5.43188 15.7865 5.33021C15.8302 5.22854 15.8531 5.11919 15.8541 5.00854C15.8551 4.89789 15.834 4.78816 15.7921 4.68575C15.7502 4.58333 15.6883 4.49029 15.6101 4.41205C15.5318 4.3338 15.4388 4.27192 15.3364 4.23002C15.2339 4.18812 15.1242 4.16704 15.0136 4.168C14.9029 4.16896 14.7936 4.19195 14.6919 4.23562C14.5902 4.2793 14.4983 4.34278 14.4214 4.42238L10.0106 8.83321L5.59973 4.42238C5.44256 4.27058 5.23206 4.18658 5.01356 4.18848C4.79507 4.19038 4.58606 4.27802 4.43155 4.43253C4.27704 4.58703 4.1894 4.79604 4.1875 5.01454C4.18561 5.23304 4.2696 5.44354 4.4214 5.60071L8.83223 10.0115L4.4214 14.4224C4.34181 14.4992 4.27832 14.5912 4.23465 14.6929C4.19097 14.7945 4.16799 14.9039 4.16702 15.0145C4.16606 15.1252 4.18715 15.2349 4.22905 15.3373C4.27095 15.4397 4.33283 15.5328 4.41107 15.611C4.48931 15.6893 4.58236 15.7512 4.68477 15.7931C4.78718 15.835 4.89692 15.856 5.00757 15.8551C5.11822 15.8541 5.22757 15.8311 5.32924 15.7875C5.43091 15.7438 5.52286 15.6803 5.59973 15.6007L10.0106 11.1899L14.4214 15.6007C14.5786 15.7525 14.7891 15.8365 15.0076 15.8346C15.2261 15.8327 15.4351 15.7451 15.5896 15.5906C15.7441 15.4361 15.8317 15.227 15.8336 15.0085C15.8355 14.79 15.7515 14.5795 15.5997 14.4224L11.1889 10.0115Z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default XIcon;
