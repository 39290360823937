export const settingsConstants = {
    SAVE_CAPTURE_SETTINGS: "SAVE_CAPTURE_SETTINGS",
    SAVE_CAPTURE_PRESETS: "SAVE_CAPTURE_PRESETS",
    SAVE_CAPTURE_PRESET: "SAVE_CAPTURE_PRESET",
    UPDATE_CAPTURE_PRESET: "UPDATE_CAPTURE_PRESET",
    DELETE_CAPTURE_PRESET: "DELETE_CAPTURE_PRESET",
    UPDATE_PRESET_INDEX: "UPDATE_PRESET_INDEX",
    SAVE_CURRENT_CAPTURE_PRESET: "SAVE_CURRENT_CAPTURE_PRESET",
    SAVE_FILE_SETTINGS: "SAVE_FILE_SETTINGS",
    SAVE_CALIBRATION_SETTINGS: "SAVE_CALIBRATION_SETTINGS",
    SAVE_LIMIT_SETTINGS: "SAVE_LIMIT_SETTINGS",

    SAVE_IMAGE_REQUESTED: "SAVE_IMAGE_REQUESTED",
    SAVE_MONITOR_DISPLAY: "SAVE_MONITOR_DISPLAY",
    SAVE_SELECTED_DISPLAY: "SAVE_SELECTED_DISPLAY",

    SAVE_ALIGN_SETTINGS: "SAVE_ALIGN_SETTINGS",
    SAVE_ALIGN_PRESETS: "SAVE_ALIGN_PRESETS",
    SAVE_CURRENT_ALIGN_PRESET: "SAVE_CURRENT_ALIGN_PRESET",
    SAVE_TARGET_ALIGN_SETTINGS: "SAVE_TARGET_ALIGN_SETTINGS",

    SAVE_POLAR_SETTINGS: "SAVE_POLAR_SETTINGS",
    SAVE_TARGET_POLAR_SETTINGS: "SAVE_TARGET_POLAR_SETTINGS",

    SAVE_FOCUS_PRESETS: "SAVE_FOCUS_PRESETS",
    SAVE_CURRENT_FOCUS_PRESET: "SAVE_CURRENT_FOCUS_PRESET",
    SAVE_TARGET_FOCUS_SETTINGS: "SAVE_TARGET_FOCUS_SETTINGS",

    SAVE_GUIDE_PRESETS: "SAVE_GUIDE_PRESETS",
    SAVE_CURRENT_GUIDE_PRESET: "SAVE_CURRENT_GUIDE_PRESET",
    SAVE_TARGET_GUIDE_SETTINGS: "SAVE_TARGET_GUIDE_SETTINGS",

    SAVE_CURRENT_MODULE: "SAVE_CURRENT_MODULE",
    SAVE_RECENTLY_USED_CATEGORY: "SAVE_RECENTLY_USED_CATEGORY",
    SAVE_RECENTLY_USED_TARGETS: "SAVE_RECENTLY_USED_TARGETS",

    RESET_DSLR_INFO_SETTINGS: "RESET_DSLR_INFO_SETTINGS",
    UPDATE_PREVIEW_PRESET: "UPDATE_PREVIEW_PRESET",
    UPDATE_LIVESTACKING_STATUS: "UPDATE_LIVESTACKING_STATUS",
    UPDATE_LIVESTACKING_TARGET: "UPDATE_LIVESTACKING_TARGET",
    UPDATE_CONNECTION_STATUS: "UPDATE_CONNECTION_STATUS",
    TOGGLE_MOUNT_QC_LOCK: "TOGGLE_MOUNT_QC_LOCK",
    SAVE_SELECTED_PROFILE: "SAVE_SELECTED_PROFILE",
    SAVE_LIVESTACKING_SETTINGS: "SAVE_LIVESTACKING_SETTINGS",

    SAVE_LOCATION: "SAVE_LOCATION",
    SAVE_WEATHER: "SAVE_WEATHER",
    SAVE_HOME_DIRECTORY: "SAVE_HOME_DIRECTORY",
    SAVE_PICTURES_DIRECTORY: "SAVE_PICTURES_DIRECTORY",
    SAVE_SKY_QUALITY: "SAVE_SKY_QUALITY",
    SAVE_CLOUDS_MAP: "SAVE_CLOUDS_MAP",
    SAVE_ZOOM_LEVEL: "SAVE_ZOOM_LEVEL",

    OVERRIDE_CAPTURE_STATUS: "OVERRIDE_CAPTURE_STATUS",

    CLEAR_UPDATED_POLAR_ERRORS: "CLEAR_UPDATED_POLAR_ERRORS",
    GET_PROGRAM_VERSION: "GET_PROGRAM_VERSION",

    CHANGE_TOUR_GUIDE: "CHANGE_TOUR_GUIDE",
    COMPLETED_TOUR_GUIDE: "COMPLETED_TOUR_GUIDE",
    TOGGLE_TOUR_GUIDE: "TOGGLE_TOUR_GUIDE",
    COMPLETE_ALL_TOUR_GUIDE: "COMPLETE_ALL_TOUR_GUIDE",
    RESET_TOUR_GUIDE: "RESET_TOUR_GUIDE",

    SET_VNC_PASSWORD: "SET_VNC_PASSWORD",

    SET_MOUNT_SETTINGS: "SET_MOUNT_SETTINGS",
    //SAVE_TARGET_MOUNT_SETTINGS: "SAVE_TARGET_MOUNT_SETTINGS",

    SAVE_LOGS_INFO: "SAVE_LOGS_INFO"
};