import React from 'react';

const FocusModuleIcon = ({ width = 25, height = 25, color = "#CECECE" }) =>
{
    return (
        <svg width={width} height={height} viewBox="0 0 15 14" fill={color} xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.7" clipPath="url(#clip0_1278_6127)">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.10534 9.2091C8.2367 9.96916 7.10459 10.4341 5.86258 10.4341C3.14395 10.4341 0.931152 8.22151 0.931152 5.50317C0.931152 2.78483 3.14395 0.572266 5.86258 0.572266C8.5812 0.572266 10.794 2.78483 10.794 5.50317C10.7936 6.69767 10.3575 7.851 9.56748 8.747L11.5401 9.68881C11.5401 9.68881 11.6979 9.88252 11.9599 10.1488C12.2719 10.4826 12.593 10.8077 12.923 11.1237L13.9036 12.0803L14.3587 12.5058L12.8645 13.9998L12.439 13.5448L11.4823 12.5642C11.1385 12.2029 10.7799 11.857 10.5073 11.6013C10.241 11.3393 10.0472 11.1815 10.0472 11.1815L9.10534 9.2091ZM5.86814 9.42307C8.04147 9.42307 9.80331 7.66142 9.80331 5.48831C9.80331 3.31521 8.04147 1.55356 5.86814 1.55356C3.69481 1.55356 1.93297 3.31521 1.93297 5.48831C1.93297 7.66142 3.69481 9.42307 5.86814 9.42307Z" />
            </g>
            <defs>
                <clipPath id="clip0_1278_6127">
                    <rect width="14.2668" height="13.4276" fill="white" transform="translate(0.645142 0.286133)" />
                </clipPath>
            </defs>
        </svg>

    )
};

export default FocusModuleIcon