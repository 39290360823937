export const targetConstants = {
    SET_HIPS_CACHE: "SET_HIPS_CACHE",
    SET_HIPS_OBJECT: "SET_HIPS_OBJECT",
    SET_HIPS_OBJECTS: "SET_HIPS_OBJECTS",
    SET_HIPS_REMOTE_LOADING_OBJECT: "SET_HIPS_REMOTE_LOADING_OBJECT",
    SET_HIPS_LOCAL_LOADING_OBJECT: "SET_HIPS_LOCAL_LOADING_OBJECT",
    SET_HIPS_OBJECT_INFO: "SET_HIPS_OBJECT_INFO",
    SET_HIPS_OBJECT_IMAGE: "SET_HIPS_OBJECT_IMAGE",
    SET_FOV_PROFILES: "SET_FOV_PROFILES",
    SET_CURRENT_FOV_PROFILE: "SET_CURRENT_FOV_PROFILE",
    CLEAR_LOADING_OBJECTS: "CLEAR_LOADING_OBJECTS",
    CLEAR_FRAMING_OBJECTS: "CLEAR_FRAMING_OBJECTS",
    SET_FRAMING_TARGET: "SET_FRAMING_TARGET",
    SET_LISTS: "SET_LISTS",
    REMOVE_OBJECTS: "REMOVE_OBJECTS",
    SET_SELECTED_TARGET: "SET_SELECTED_TARGET",

    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_ACTIVE_LIST: "SET_ACTIVE_LIST",
};