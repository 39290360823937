export const Palette = {
  grey11: "#111111",
  grey22: "#222222",
  grey33: "#333333",
  grey44: "#444444",
  grey66: "#666666",
  grey99: "#999999",
  greycc: "#cccccc",
  greydd: "#dddddd",
  greyee: "#eeeeee",
  white: "#ffffff",
  white70: "rgba(255,255,255,0.7)",
  white50: "rgba(255,255,255,0.5)",
  white30: "rgba(255,255,255,0.3)",
  white20: "rgba(255,255,255,0.2)",
  white10: "rgba(255,255,255,0.1)",
  white05: "rgba(255,255,255,0.05)",
  orange: "#ff6600",
  blue: "#0066FF",
  error: "#cc0000",
  green: "#00ff66",
  darkGreen: "#11aa11",
  yellow: "#cbc92b",
  red: "#cb1f1f",
  darkRed: "#8a1414",
  magenta: "#a222a6",
  dark_magenta: "#402559",
  running: "#FE994A",
  complete: "#7bc17e",
  userinput: "#4682b4",
  suspended: "#c071fe",
  contrastRed: "#8D0000",
  contrastGreen: "#00B300",
  contrastYellow: "#FFFF00",
  contrastPurple: "#D395F9",
  contrastBlue: "#003F9E",
  contrastGrey: "#2B2A2A",
  nightRed: "#a60606",
};

export const navContainerTheme = {
  colors: {
    primary: "#136",
  },
};
