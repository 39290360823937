const breakpoints = {
	values: {
		xxs: 0,
		xs: 480,
		sm: 640,
		md: 768,
		lg: 1024,
		xl: 1280,
		"2xl": 1440,
	},
};

export default breakpoints;
