import { Palette } from "@common/shared/common/styles";

const linearProgress = {
	styleOverrides: {
		root: { backgroundColor: `${Palette.orange}30` },
		bar: { backgroundColor: Palette.orange },
	},
};

export default linearProgress;
