export const deviceConstants = {
    DEVICE_SCAN_PROGRESS: "DEVICE_SCAN_PROGRESS",
    DEVICE_SCAN_FAILED: "DEVICE_SCAN_FAILED",
    DEVICE_SCAN_TOGGLED: "DEVICE_SCAN_TOGGLED",
    DEVICE_ADD: "DEVICE_ADD",
    DEVICE_UPDATE: "DEVICE_UPDATE",
    DEVICE_UPDATE_IP: "DEVICE_UPDATE_IP",
    DEVICE_REMOVE: "DEVICE_REMOVE",
    DEVICE_SELECT: "DEVICE_SELECT",
    DEVICE_CLEAR_ALL: "DEVICE_CLEAR_ALL",
    DEVICE_ADD_REG: "DEVICE_ADD_REG",
    DEVICE_UPDATE_REG: "DEVICE_UPDATE_REG",
    DEVICE_CLEAR_REG: "DEVICE_CLEAR_REG",
    GET_DEVICES: "GET_DEVICES",
    DEVICE_GET: "DEVICE_GET",
    KSTARS_STATUS: "KSTARS_STATUS",
    EKOSLIVE_STATUS: "EKOSLIVE_STATUS",
    MIN_OS_UPDATE_REQUIRED: "MIN_OS_UPDATE_REQUIRED",
    DEVICE_UPDATE_AVAILABLE: "DEVICE_UPDATE_AVAILABLE",
    LOGOUT_USER_ACCOUNT: "LOGOUT_USER_ACCOUNT",
    CLEAR_SERVER_MESSAGE: "CLEAR_SERVER_MESSAGE"
};
