import React from "react";

const UsersIcon = ({ width = 24, height = 24, color = "inherit" }) =>
{
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			color={color}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.99967 7.33203C9.10424 7.33203 9.99967 6.4366 9.99967 5.33203C9.99967 4.22746 9.10424 3.33203 7.99967 3.33203C6.8951 3.33203 5.99967 4.22746 5.99967 5.33203C5.99967 6.4366 6.8951 7.33203 7.99967 7.33203ZM7.99967 12.6654C10.2088 12.6654 11.9997 11.7699 11.9997 10.6654C11.9997 9.5608 10.2088 8.66536 7.99967 8.66536C5.79054 8.66536 3.99967 9.5608 3.99967 10.6654C3.99967 11.7699 5.79054 12.6654 7.99967 12.6654ZM4.25411 8.67806C2.60767 8.77959 1.33301 9.48142 1.33301 10.3321C1.33301 11.0334 2.19933 11.6336 3.42609 11.8796C3.15196 11.5084 2.99967 11.0976 2.99967 10.6654C2.99967 9.90316 3.47352 9.20728 4.25411 8.67806ZM12.9996 10.6654C12.9996 11.0976 12.8473 11.5084 12.5731 11.8796C13.7999 11.6336 14.6662 11.0334 14.6662 10.3321C14.6662 9.48142 13.3916 8.77959 11.7451 8.67806C12.5257 9.20728 12.9996 9.90316 12.9996 10.6654ZM10.4785 7.02229C10.8074 6.54097 10.9997 5.95896 10.9997 5.33203C10.9997 5.12399 10.9785 4.9209 10.9382 4.72479C11.0629 4.68617 11.1955 4.66536 11.333 4.66536C12.0694 4.66536 12.6663 5.26232 12.6663 5.9987C12.6663 6.73508 12.0694 7.33203 11.333 7.33203C11.0079 7.33203 10.7099 7.21566 10.4785 7.02229ZM4.66634 4.66536C4.8038 4.66536 4.9364 4.68617 5.06116 4.72479C5.02085 4.9209 4.99967 5.12399 4.99967 5.33203C4.99967 5.95896 5.19198 6.54097 5.52081 7.02229C5.28942 7.21566 4.99147 7.33203 4.66634 7.33203C3.92996 7.33203 3.33301 6.73508 3.33301 5.9987C3.33301 5.26232 3.92996 4.66536 4.66634 4.66536Z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default UsersIcon;
