import { messageConstants } from "../constants/messages.constants";
import { WEBSOCKET_MESSAGE } from "redux-websocket";
import commands from "../../commands";

/**
 * Used to manage messages and conversations
 **********Sample in JSON form**********
 * {
  "messages": {
    "conversation123": [
      {
        "id": "msg1",
        "senderId": "user1",
        "text": "Hello there",
        "timestamp": 1710941567147,
        "status": "read"
      }
    ]
  },
  "conversations": [
    {
      "id": "conversation123",
      "name": "John Doe",
      "avatar": "avatar-url",
      "unreadCount": 2,
      "lastMessage": "Hello there",
      "lastTime": 1710941567147,
      "lastMessageId": "msg1",
      "isOnline": true
    }
  ],
  "activeConversation": "conversation123",
  "totalUnreadCount": 5
}
 */
const initialState = {
    // Messages organized by conversation ID
    messages: {},
    
    // List of conversations
    conversations: [],
    
    // Currently active conversation ID
    activeConversation: null,
    
    // Total unread message count
    totalUnreadCount: 0
};

export const messages = (state = initialState, action) => {
    switch (action.type) {
        case WEBSOCKET_MESSAGE:
            if (action.payload.event.target.url.includes("/message/user") === false)
                return state;
                
            const message = JSON.parse(action.payload.data);
            
            // Handle new message from WebSocket
            if (message.type === "new_message") {
                const { id, senderId, receiverId, text, timestamp, senderName, status } = message.payload;
                const newMessage = {
                    id,
                    senderId,
                    receiverId,
                    text,
                    timestamp,
                    status: status || "received"
                };
                
                // --- Handle incoming new_message ---
                const SENDER_NAME_LOWER_TRIMMED = senderName?.trim().toLowerCase();
                
                // STRICT check: Find index based *only* on trimmed, lowercased name
                const existingConvIndex = state.conversations.findIndex(
                    c => SENDER_NAME_LOWER_TRIMMED && c.name?.trim().toLowerCase() === SENDER_NAME_LOWER_TRIMMED
                );

                let nextConversations = [...state.conversations];
                let nextMessages = { ...state.messages };
                let nextTotalUnreadCount = state.totalUnreadCount;
                let targetConversationIdForMessages; // The ID to use for storing messages

                // Check if a match was found *strictly by name*
                if (existingConvIndex > -1) { 
                    // --- MATCH FOUND BY NAME ---
                    const existingConversation = nextConversations[existingConvIndex];
                    // IMPORTANT: Use the ID from the EXISTING conversation for message storage
                    targetConversationIdForMessages = existingConversation.id; 
                    const isConversationActive = state.activeConversation === targetConversationIdForMessages;

                    // Update the matched conversation
                    const updatedConversation = {
                        ...existingConversation,
                        lastMessage: text,
                        lastTime: timestamp,
                        lastMessageId: id,
                        // Only increment unread if not active
                        unreadCount: isConversationActive ? 0 : (existingConversation.unreadCount || 0) + 1,
                        // Optionally update isOnline status if provided by payload, otherwise keep existing
                        isOnline: message.payload.isOnline !== undefined ? message.payload.isOnline : existingConversation.isOnline,
                        // Ensure the name is updated to the potentially slightly different version from the payload if needed
                        name: senderName || existingConversation.name 
                    };

                    // Remove from current position and add to the front
                    nextConversations.splice(existingConvIndex, 1); // Use the unified index
                    nextConversations.unshift(updatedConversation);

                    // Update total unread count
                    if (!isConversationActive) {
                        nextTotalUnreadCount += 1;
                    }
                } else {
                    // --- NO MATCH FOUND BY NAME --- Create a new conversation
                    // Only create if no match by name was found. Use senderId from payload as the new conversation's ID
                    // and also as the key for storing its messages.
                    targetConversationIdForMessages = senderId; 
                    const newConversation = {
                        id: targetConversationIdForMessages, // Use senderId from payload
                        name: senderName || "Unknown User",
                        avatar: "", // Default avatar
                        unreadCount: 1, // Starts with 1 unread
                        lastMessage: text,
                        lastTime: timestamp,
                        lastMessageId: id,
                        isOnline: message.payload.isOnline !== undefined ? message.payload.isOnline : true, // Use status from payload or default to true
                    };
                    nextConversations.unshift(newConversation); // Add to the front
                    nextTotalUnreadCount += 1; // Increment total unread
                }

                // Add the message to the list associated with the determined targetConversationIdForMessages
                const currentMessagesForConv = nextMessages[targetConversationIdForMessages] || [];
                nextMessages[targetConversationIdForMessages] = [...currentMessagesForConv, newMessage];

                return {
                    ...state,
                    conversations: nextConversations,
                    messages: nextMessages,
                    totalUnreadCount: nextTotalUnreadCount,
                };
                // Removed extra closing brace here
            } else if (message.type === "message_sent_confirmation") {
                // Handle sent message confirmation from server
                const { id, senderId, receiverId, text, timestamp, status } = message.payload;
                const newMessage = {
                    id,
                    senderId: 'self', // Mark as sent by self
                    receiverId,
                    text,
                    timestamp,
                    status: status || "sent"
                };
                
                // Find conversation with the receiver using robust string comparison
                const existingConvIndex = state.conversations.findIndex(c => String(c.id) === String(receiverId));
                
                if (existingConvIndex > -1) {
                    // Update existing conversation
                    const updatedConversations = state.conversations.map((c, index) => {
                        if (index === existingConvIndex) {
                            return {
                                ...c,
                                lastMessage: text,
                                lastTime: timestamp,
                                lastMessageId: id
                            };
                        }
                        return c;
                    });
                    
                    // Sort conversations to put the most recent on top
                    updatedConversations.sort((a, b) => b.lastTime - a.lastTime);
                    
                    // Update messages for this conversation
                    const conversationMessages = state.messages[receiverId] || [];
                    
                    return {
                        ...state,
                        conversations: updatedConversations,
                        messages: {
                            ...state.messages,
                            [receiverId]: [...conversationMessages, newMessage]
                        }
                    };
                } else {
                    // We shouldn't normally reach here as conversations should exist,
                    // but handle it gracefully just in case
                    const newConversation = {
                        id: receiverId,
                        name: "Unknown", // This should be updated elsewhere
                        avatar: "",
                        unreadCount: 0,
                        lastMessage: text,
                        lastTime: timestamp,
                        lastMessageId: id,
                        isOnline: false
                    };
                    
                    return {
                        ...state,
                        conversations: [newConversation, ...state.conversations],
                        messages: {
                            ...state.messages,
                            [receiverId]: [newMessage]
                        }
                    };
                }
            } else if (message.type === "message_read_confirmation") {
                // Handle message read confirmation
                const { conversationId, messageIds } = message.payload;
                
                if (!state.messages[conversationId]) return state;
                
                // Update status of the specified messages
                const updatedMessages = state.messages[conversationId].map(msg => {
                    if (messageIds.includes(msg.id)) {
                        return { ...msg, status: "read" };
                    }
                    return msg;
                });
                
                return {
                    ...state,
                    messages: {
                        ...state.messages,
                        [conversationId]: updatedMessages
                    }
                };
            } else if (message.type === "user_status_change") {
                // Handle user status change
                const { username, isOnline } = message.payload;
                
                // Update conversation online status
                const updatedConversations = state.conversations.map(c => {
                    if (c.name === username) {
                        return { ...c, isOnline };
                    }
                    return c;
                });
                
                return {
                    ...state,
                    conversations: updatedConversations
                };
            } else {
                return state;
            }
            
        case messageConstants.SET_MESSAGES:
            return {
                ...state,
                messages: {
                    ...state.messages,
                    [state.activeConversation]: action.payload
                }
            };
            
        case messageConstants.ADD_MESSAGE:
            const conversationId = action.payload.conversationId || state.activeConversation;
            const conversationMessages = state.messages[conversationId] || [];
            
            return {
                ...state,
                messages: {
                    ...state.messages,
                    [conversationId]: [...conversationMessages, action.payload]
                }
            };
            
        case messageConstants.SET_CONVERSATIONS:
            return {
                ...state,
                conversations: action.payload
            };
            
        case messageConstants.UPDATE_CONVERSATION:
            return {
                ...state,
                conversations: state.conversations.map(c => 
                    c.id === action.payload.id ? { ...c, ...action.payload } : c
                )
            };
            
        case messageConstants.SET_UNREAD_COUNT:
            return {
                ...state,
                totalUnreadCount: action.payload
            };
            
        case messageConstants.SET_ACTIVE_CONVERSATION:
            // Reset unread count for the active conversation
            const updatedConversations = state.conversations.map(c => {
                if (c.id === action.payload) {
                    return { ...c, unreadCount: 0 };
                }
                return c;
            });
            
            // Calculate new total unread count
            const newTotalUnread = updatedConversations.reduce(
                (total, conv) => total + (conv.unreadCount || 0), 0
            );
            
            return {
                ...state,
                activeConversation: action.payload,
                conversations: updatedConversations,
                totalUnreadCount: newTotalUnread
            };
            
        case messageConstants.UPDATE_MESSAGE_STATUS:
            if (!state.activeConversation) return state;
            
            const { messageId, status } = action.payload;
            const activeMessages = state.messages[state.activeConversation] || [];
            
            return {
                ...state,
                messages: {
                    ...state.messages,
                    [state.activeConversation]: activeMessages.map(msg => 
                        msg.id === messageId ? { ...msg, status } : msg
                    )
                }
            };
            
        case messageConstants.CLEAR_MESSAGES:
            return initialState;
            
        default:
            return state;
    }
};
