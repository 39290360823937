import React, { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { login } from "@redux/actions";
import userService from "@services/userService";
import LoginForm from "@components/sections/LoginForm";
import RegisterModal from "@components/sections/RegisterForm";
// import ForgetPassword from "@components/ForgetPassword";

const MODAL_NAMES = {
	login: "LOGIN",
	register: "REGISTER",
	forgetPassword: "FORGET_PASSWORD",
};

const AuthButtons = ({mode="button", setMode}) =>
{
	const dispatch = useDispatch();
	const [activeModal, setActiveModal] = useState(mode === "LOGIN" ? MODAL_NAMES.login : "");

	useEffect(() => {
		if (mode === "LOGIN") setActiveModal(MODAL_NAMES.login);
	}, [mode]);

	const closeModal = useCallback(() =>
	{
		setActiveModal("");
		if (setMode) setMode("");
	}, [setActiveModal, setMode]);

	const handleOpenLogin = () => {
		setActiveModal(MODAL_NAMES.login);
	};

	const handleOpenRegister = () => {
		setActiveModal(MODAL_NAMES.register);
	};

	const handleSubmit = useCallback(
		async (credentials, type = "login") =>
		{
			const user =
				type === "login"
					? await userService.login(credentials)
					: await userService.register(credentials);
			if (typeof user !== "string") dispatch(login(credentials));
			return user;
		},
		[dispatch],
	);

	return (
		<div className="flex-center gap-2 lg:gap-4">
			{mode === "button" && (
				<>
					<button
						className="auth-button min-w-16 lg:min-w-24 focus-visible:text-primary-orange focus-visible:border focus-visible:border-transparent hover:bg-primary-orange border hover:border-transparent px-4 lg:px-6 bg-transparent border-white"
						type="button"
						onClick={() => setActiveModal(MODAL_NAMES.login)}
						name="login"
					>
						Login
					</button>
					<button
						className="auth-button min-w-16 lg:min-w-24 px-[0.875rem] lg:px-[1.125rem] focus-visible:bg-transparent focus-visible:text-primary-orange"
						type="button"
						onClick={() => setActiveModal(MODAL_NAMES.register)}
						name="sign up"
					>
						SignUp
					</button>
				</>
			)}

			<LoginForm
				onClose={closeModal}
				handleSubmit={handleSubmit}
				openForgetForm={() => setActiveModal(MODAL_NAMES.forgetPassword)}
				open={activeModal === MODAL_NAMES.login}
				onSwitchToRegister={handleOpenRegister}
			/>

			<RegisterModal
				onClose={closeModal}
				handleSubmit={handleSubmit}
				open={activeModal === MODAL_NAMES.register}
				onSwitchToLogin={handleOpenLogin}
			/>
			{/* <Modal
				onClose={closeModal}
				open={activeModal === MODAL_NAMES.forgetPassword}
			>
				<ForgetPassword onClose={closeModal} />
			</Modal> */}
		</div>
	);
};

export default AuthButtons;
