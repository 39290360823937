import React from 'react'

const SchedulerModuleIcon = ({ width = 25, height = 25, color = "#CECECE" }) =>
{
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.7">
                <path d="M16.5604 3.19922H8.45519L6.609 4.6274L16.5604 12.2883V3.19922ZM2.86259 3.71225L1.59277 4.68979L3.95229 6.50621V15.6784H15.867L17.3259 16.8016L18.5957 15.824L2.86259 3.71225Z" />
            </g>
        </svg>
    )
}

export default SchedulerModuleIcon