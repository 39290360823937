const fr = {
	general: {
		home: "Accueil",
		next: "prochain",
		ok: "D'accord",
		done: "Fait",
		cancel: "Annuler",
		confirm: "Confirm",
		auto: "Auto",
		apply: "Appliquer",
		enter: "Enter",
		scan: "Scan",
		save: "sauvegarder",
		save_as: "Save as",
		overwrite: "Overwrite",
		select: "Sélectionner",
		hardware: "Hardware",
		signal: "Signal",
		usb: "USB",
		devices: "Devices",
		connect: "Connecter",
		disconnect: "Déconnecter",
		schedule: "Schedule",
		walk: "Walk",
		yes: "Oui",
		no: "Non",
		ignore: "Ignore",
		error: "Erreur",
		back: "Retour",
		delete: "Supprimer",
		remove: "Retirer",
		online: "En ligne",
		offline: "Hors ligne",
		cloud: "Cloud",
		remote: "Distant",
		preset: "Préréglage",
		camera: "Caméra",
		focuser: "Focuser",
		filter_wheel: "Roue à filtre",
		filter: "Filtre",
		exposure: "Exposition",
		binning: "Binning",
		left: "Left",
		top: "Top",
		action: "Action",
		scope_type: "Type de Tube",
		solver_type: "Type de solveur",
		type: "Type",
		driver: "Driver",
		gain: "Gain",
		offset: "Décalage",
		format: "Format",
		iso: "ISO",
		count: "Nombre",
		delay: "Retard",
		status: "Statut",
		target: "Cible",
		angle: "Angle",
		sep_profile: "Profil SEP",
		direction: "Direction",
		rotation: "Rotation",
		automatic: "Automatique",
		manual: "Manuel",
		progress: "Progression",
		position_angle: "PA",
		details: "Details",
		skip: "Skip",
		updated: "Updated",
		new: "New",
		remote_support: "Remote Support",
		logout: "Logout",
		setting: "Setting",
		hours: "Hours",
		minutes: "Minutes",
		seconds: "Seconds",
		introduction: "Introduction",
		examples: "Examples",
		chat: "Chat",
		controls: "Controls",
		balance: "Balance",
		white: "White",
		black: "Black",
		datepick: "Pick a date",
		north: "North",
		south: "South",
		east: "East",
		west: "West",
		released: "Released",
		history: "History",
		basic: "Basic",
		expert: "Expert",
		position: "Position",

		azimuth: "Azimut",
		altitude: "Altitude",
		tags: "Mots clés",
		filename: "Nom du fichier",
		size: "Taille",
		frame: "Cadre",
		temperature: "Température",
		name: "Nom",
		date: "Date",
		resolution: "Résolution",
		monitor: "Monitor",
		clear_all: "Clear All",
		pixels: "Pixels",

		select_file: "Select file",
		select_folder: "Select folder",
		selected_dir: "selected folder",
		new_folder: "Enter new folder name",
		create_new_folder: "Create new folder in",
		empty_folder: "Folder is Empty",

		train: "Train",
		no_data_found: "No data found",
		track: "Track",
		jobs: "Jobs",
		category: "Categories",
		profile: "Profile",
		arcmin: "arcmin",
		calculate: "Calculate",
		update: "Update",
		center: "Center",
		learn_more: "Learn more",

		select_option: "Sélectionnez l'option...",
		search: "Chercher...",
		no_results: "Aucun résultat",
		on: "Marche",
		off: "Arrêt",
		go: "ALLER",
		add: "AJOUTER",
		load: "Load",
		edit: "ÉDITER",
		refresh: "Rafraichir",
		reset: "Réinitialiser",
		reset_all: "Tout réinitialiser",
		start: "Début",
		stop: "Arrêtez",
		stopping: "Arrêt",
		clear: "Effacer",
		solve: "Résoudre",
		parked: "Parquée",
		unparked: "Dé Parquée",
		open: "Ouvrir",
		close: "Fermé",
		opened: "Ouvrir",
		closed: "Fermé",
		enable: "Activer",
		disable: "Désactiver",
		select_time: "Durée",
		set: "Définir",
		logging: "Logging",
		drivers: "Drivers",
		network: "Network",
		submit: "Submit",
		execute: "Execute",
		retry: "Retry",

		// Confirm Delete Alert
		alert_confirm_delete_title: "Confirmer Suppression",
		alert_delete_profile_body:
			"Voulez-vous vraiment supprimer le profil sélectionné ?",
		alert_delete_scope_body:
			"Êtes-vous sûr de vouloir supprimer le scope sélectionné?",

		// Confirm
		alert_confirmation_title: "Confirmation",
		alert_confirmation_files:
			"Are you sure you would like to delete the selected files",
		alert_confirmation_body:
			"Are you sure you want to create {0} with this name?",
		alert_overwrite_body:
			"The file '{0}' already exists. Do you wish to overwrite it?",

		network_error:
			"Veuillez vous assurer que votre StellarMate est connecté à votre réseau",
		internet_required:
			"Veuillez vous assurer que vous êtes connecté à Internet",
		alert_comm_error_title: "Erreur de communication",
		alert_comm_error_body:
			"Échec de la communication avec StellarMate. Veuillez vous assurer qu'il est connecté à votre réseau.",
		ekoslive_down_title: "EkosLive est en panne",
		ekoslive_down_body:
			"EkosLive n'est pas en cours d'exécution, essayez de redémarrer StellarMate ou contactez l'assistance StellarMate.",
		kstars_down_title: "KStars est en panne",
		kstars_down_body:
			"KStars n'est pas en cours d'exécution, essayez de redémarrer StellarMate ou contactez le support StellarMate.",
		reset_default: "Reset to default",
		external_storage: "Stokage Externe",
		success: "Success",
		failed: "Failed",
		file_too_large: "File is too large",
		public: "Public",
		private: "Private",
		label: "Label",

		users: "Users",
		title: "Title",
		submitted_by: "Submitted By",
		submitted_date: "Submitted Date",
		publish_status: "Publish Status",
		submission_status: "Submission Status",
		access_level: "Access Level",

		description: "Description",
		acquisition_details: "Acquisition Details",
		models: "Models",
		manufacturers: "Manufacturers",
		logo: "Logo",
		approve: "Approve",
		reject: "Reject",
		confirm_approve: "Confirm Approve",
		confirm_reject: "Confirm Reject",
		confirm_ban: "Confirm Ban",
		confirm_delete: "Confirm Delete",
		confirm_ignore: "Confirm Ignore",

		product_range: "Product Range",
		image: "Image",

		owner: "Owner",
		country: "Country",
		region: "Region",
		pictures_captured: "Pictures Captured",
		latitude: "Latitude",
		longitude: "Longitude",
		elevation: "Elevation",
		no_filter: "No Filter",

		new_observatory: "New Observatory",

		go_back: "Go Back",
		go_home: "Go back to Home",
		go_to_feed: "Go to Feed",
		go_to_users: "Go to Users",
		go_to_equipment: "Go to Equipment",
		go_to_observatories: "Go to Observatories",
		absent_page: "Oops! The page you're looking for doesn't exist.",
		absent_user: "Oops! The user you're looking for doesn't exist.",

		imaging: "Imaging",
		engage: "Engage",
		trash: "Trash",
		unpublish: "Unpublish",

		duplicate: "Duplicate",
		blacklist: "Blacklist",
		ban: "Ban",
		delete: "Delete",
		manufacturer_tip_title: "Adding New Equipment Manufacturers",
		manufacturer_tip_section_1:
			"When adding new manufacturers, enter only the manufacturer name (e.g., Canon, Meade) in this section.",
		manufacturer_tip_section_2:
			"Specific models (like Meade LX200) should be added later through the manufacturer's dedicated page.",
		delete_post_description:
			"Are you sure you want to delete this post? This action cannot be undone and all associated comments will also be removed.",
	},
	darkLibrary: {
		title: "Dark Library",
		darks: "Darks",
		defects: "Defects",
		prefer: "Prefer",
		create_darks_title: "Créer Darks",
		create_defects_title: "Créer la Defect Map",
		view_masters_title: "View Masters",
		progress: "Progression",

		create_darks: {
			exposure_range: "Plage d'expo.",
			to: "à",
			temp_range: "Plage de temp.",
			binning: "Binning",
			binning_one: "1x1",
			binning_two: "2x2",
			binning_four: "4x4",
			total_images: "Total",
		},

		create_defect_map: {
			master_dark: "Master Dark",
			bad_pixels: "Bad Pixels",
			hot_pixels: "Pixels chauds",
			generate_map: "Generer carte",
			save_map: "Sauvegardé",
			deviation: "σ",
		},
	},
	achievements: {
		score: "Total Score",
		badge: "Badge",
		achievements: "Achievements",
		unlocked: "Achievement Unlocked",
		points: "Points",
		completed: "Completed",
		not_completed: "Not completed",
		capture_preview_title: "First Light!",
		ten_sequences_title: "So it begins!",
		mount_goto_title: "Magic Fingers",
		video_recording_title: "Director’s Cut",
		weather_check_title: "Cloud Magnet",
		live_stacking_title: "Let there be details",
		create_darks_title: "Embrace the dark side",
		create_defect_title: "Cosmic Makeup",
		import_mosaic_title: "Mosaic Weaver",
		messier_captured_title: "MXXXX (e.g. M1)",
		all_messier_title: "Cosmic Marathon",
		scheduler_title: "Robotic Master",
		capture_master_title: "Sky Master",
		capture_legend_title: "Sky Legend",
		paa_title: "Perfectionist",
		guide_rms_title: "Bullseye!",

		capture_preview_description: "Capture a Preview",
		ten_sequences_description: "Capture a sequence with 10 counts",
		mount_goto__description:
			"Use Target GOTO by holding on the object for 3 seconds when the new image is captured",
		video_recording_description: "Record video for 1 minute",
		weather_check__description:
			"Use Cloud Map in weather info, Zoom in to at least 8x to check weather",
		live_stacking_description: "Live stacking. Perform at least 5 images",
		create_darks_description: "Create Darks of total 50 Images",
		create_defect_description:
			"Generate hot / cold pixels in Defect map above 80",
		import_mosaic_description: "Import Mosaics from telescopios",
		messier_captured_description: "A messier object is captured",
		all_messier_description: "All Messier objects were captured",
		scheduler_description:
			"Finish a scheduler job worth 2 or more hours of image data.",
		capture_master_description: "Capture a total of 500 images",
		capture_legend_description: "Capture a total of 1000 images",
		paa_description: "Finish PAA with box error lower than 30 arcsecs.",
		guide_rms_description: "Achieve total RMS guiding below 0.5 arcsecs.",
		filtered_image_description: "Capture a narrowband image",
		gallery_image_description: "Gallery Image downloaded",

		alert_reset_title: "Reset achievements",
		alert_agree_reset_body: "Are you sure you want to reset all achievements?",
		no_description: "No description",
		complete_tour_guide: "Complete Tour Guide",
		file_stored: "File Stored",
	},
	tourGuide: {
		tour_guide: "Tour Guide",
		previous: "Previous",
		finish: "Finish",
		title_devices_list: "StellarMate Devices List",
		title_device_actions: "Device Actions",
		title_profiles: "Profiles",
		title_port_selector: "Port Selector",
		title_trains: "Optical trains",
		title_weather_bar: "Weather bar",
		title_cloud_report: "Cloud Report",
		title_next: "What's next?",

		title_focus: "Focus",
		title_align: "Align",
		title_guide: "Guide",
		title_capture: "Capture",
		title_mount: "Mount",
		title_observatory: "Observatory",
		title_scheduler: "Scheduler",
		title_indi: "INDI Control Panel",
		title_quick_controls: "Quick Controls",
		title_preview: "Preview",
		title_framing: "Framing",
		title_live_video: "Live Video",
		title_stop: "Stop",
		title_live_stacking: "Live Stacking",
		title_quick_settings: "Qucik Camera Settings",

		title_targets_info: "About Targets",
		title_search_bar: "Search bar",
		title_time_controls: "Time Controls",
		title_target_controls: "Targets Controls",
		title_object_info: "Object info",
		title_fov: "Target Field Of View",
		title_target_action: "Target Action",
		title_stella_prompt: "Stella prompt",

		title_focus_initial: "Current Position",
		title_focus_steps: "Target Position",
		title_focus_size: "Step Size",

		title_basic_paa: "Basic Mode",
		description_basic_paa:
			"Basic mode is a streamlined workflow for astrophotography beginners.",
		title_basic_controls: "Workflow steps",
		description_basic_controls: "Navigate to any workflow step at any time.",
		title_toggle_mode: "Mode Switch",
		description_toggle_mode: "Switch between Expert & Basic modes.",

		title_paa_controls: "Polar Alignment controls",
		description_paa_controls: "Toggle Polar Alignment and Park Mount",

		title_paa_message: "Guided instructions",
		description_paa_message:
			"Follow guided steps until Polar Alignment is complete.",

		title_targets_mode: "Targets Selection",
		description_targets_mode:
			"Search for astronomical objects across different classes. View altitude vs. time charts and observability data for any celestial target.",

		title_overlay_type: "Object Class",
		description_overlay_type:
			"Select object classes to view celestial targets sorted by magnitude.",

		title_target_chart: "Altitude vs Time",
		description_target_chart:
			"Shows Altitude vs Time graph with GO button to slew and center target.",

		title_basic_focus: "Focus your target",
		description_basic_focus:
			"Use electronic focuser for crisp images or focus manually while monitoring HFR chart (lower is better).",

		title_focus_settings: "Focus Controls",
		description_focus_settings:
			"Frame target with clearly resolvable stars using loop button. Set appropriate step size, then press play for fine autofocus.",

		title_focus_graph: "Focus Graph",
		description_focus_graph:
			"View Half-Flux-Radius (HFR) vs Step chart showing focus quality. Autofocus creates a V-curve to find the Critical-Focus-Zone (CFZ). When looping, the HFR is plotted for each exposure.",

		title_focus_image: "Focus Image",
		description_focus_image:
			"Monitor star sharpness in focus image. Toggle full screen for detailed view.",

		description_targets_fov:
			"Displays camera FOV indicator over target with size (arcmin) in top corner and orientation (degrees) in top-right.",

		title_basic_guide: "Auto Guiding",
		description_basic_guide:
			"Use guide scope or Off-Axis guider to help Ekos keep targets centered during long-exposure imaging.",

		title_guide_controls: "Guide Controls",
		description_guide_controls:
			"Adjust guide camera settings and use framing button to ensure stars are resolvable. Set Guide-Via to mount (default) or ST4. Press play to start calibration and autoguiding.",

		title_guide_chart: "Guide Chart",
		description_guide_chart:
			"Guide trend graph showing tracking accuracy with RMS errors for RA (Green), DEC (Blue), and total (Red) over time.",

		title_guide_image: "Guide Image",
		description_guide_image:
			"Guide image with multi-star tracking overlay. Toggle full screen for detailed view.",

		title_basic_capture: "Capture Planner",
		description_basic_capture:
			"Configure image capture settings (exposure, filter, binning, etc.) and add to your sequence. Press play to begin capturing your imaging targets.",

		title_capture_controls: "Capture Controls",
		description_capture_controls:
			"Configure capture settings, set count, then press add to include in sequence queue.",

		title_manage_sequences: "Manage sequqences",
		description_manage_sequences:
			"Save, Load, Delete and Organize your imaging sequences",

		title_capture_actions: "Capture actions",
		description_capture_actions:
			"Press play to start imaging sequence. Toggle live-stacking for real-time combined images while preserving individual frames.",

		title_summary: "Summary",
		description_summary:
			"Monitor all operations (guide, focus, align, capture) and view captured images in one display.",

		title_histogram: "Toggle Histogram",
		description_histogram:
			"Toggle histogram view. Adjust shadows, midtones, and highlights for sequence images only (not for livestacked images).",

		description_devices_list:
			"This is the list of automatically discovered and manually added StellarMate units. Tap RESCAN to detect new StellarMate units on the network.",
		description_device_actions:
			"Remove a device from the list, perform a factory reset, or log out.",
		description_profiles:
			"Manage your astronomy equipment in Equipment Profiles. All equipment must be powered and connected to StellarMate before starting a profile. Once a profile is started, configure the Optical Trains and then tap Ekos to start your astrophotography session.",
		description_port_selector:
			"After a profile is started for the first time, select the serial and/or network settings for your devices.",
		description_trains:
			"Set up how your equipment is arranged using Optical trains. Assign each device to a specific function. Create a train for each camera.",
		description_weather_bar:
			"Brief weather report and current site Bortle class",
		description_cloud_report: "3-hours Cloud overlay.",
		description_next:
			"Explore applicable astronomical targets by tapping the Targets tab. Use Go & Solve to center your target in the camera frame. Open the Framing Assistant to achieve the perfect desired orientation. Head over to Ekos tab to set up imaging sequences and live stack images.",

		description_focus: "Focus the camera by using a motorized focuser.",
		description_align:
			"Center the mount exactly on target by plate-solving an image.",
		description_guide:
			"Keep the mount locked to your target during tracking to enable long exposures.",
		description_capture:
			"Create sequences to capture images using configurable settings. Manage filter wheel settings and Dark Library.",
		description_mount:
			"Toggle tracking, parking, and meridian flip settings. Configure auto-park.",
		description_observatory: "Control dome and dust-cap equipment.",
		description_scheduler:
			"Automate complete astrophotography session by selecting target and sequence file. Import mosaics from Telescopius.",
		description_indi: "Direct low-level access to equipment properties.",
		description_quick_controls:
			"Quick access to mount, camera, and rotator controls.",
		description_preview: "Capture a single preview frame.",
		description_framing: "Loop exposures indefinitely until stopped",
		description_live_video:
			"Start live video streams and record videos to storage.",
		description_stop: "Stop any ongoing exposures or recordings.",
		description_live_stacking:
			"Live stack images to increase signal to noise ratio. If an existing capture sequence is running, live stacking will use incoming images otherwise it will loop exposures using settings in Quick Camera Settings.",
		description_quick_settings:
			"Select active train and configure camera and filter wheel settings.",

		description_targets_info:
			"Targets is the StellarMate Planning tool to streamline your observation session. Search from thousands of objects and filter them using simple criteria. Use the Framing Assistant to frame your targets.",
		description_search_bar:
			"Filter objects in the existing list or search for new objects by entering the name and tapping the search button.",
		description_time_controls:
			"If Ekos is offline, adjust the target date and time calculations.",
		description_target_controls:
			"Check out twilight information, manage FOVs, adjust filters, and select object types.",
		description_object_info: "Object magnitude, rise, transit, and set times.",
		description_fov: "Tap to enter Framing Assistant mode.",
		description_target_action:
			"Add target to favorites or custom list. Command a GOTO only or a GOTO followed by capture and solve. If Ekos is offline, schedule the target.",
		alert_guided_tour_title: "Take a guided tour on Stellarmate App features",

		description_stella_intro:
			"Stella is your personal smart digital assistant. You can use voice or text to communicate with Stella. Ask it about any topic in astronomy.",
		description_stella_example: "View example prompts.",
		description_stella_chat: "View the chat history.",
		description_stella_input:
			"Enter your prompts to request tasks or retrieve data.",
		description_stella_other_function:
			"You can also interact with Stella using voice and attach files.",
		description_align_paa:
			"Polar align your equatorial mount to achieve better tracking & guiding.",
		description_align_load: "Load and Plate Solve an image (JPG, FITS, XISF)",
		description_align_controls:
			"You can view Align Chart, Image, Settings and Quick Access Settings. You can also start Aligning",
		description_align_solution: "Plate solving solution",

		description_focus_initial: "Current focuser position and Focus Advisor",
		description_focus_steps: "Target position",
		description_focus_size: "Steps size when running autofocus",
		description_focus_exposure: "Exposure duration and Framing toggle",
		description_focus_controls:
			"You can view Focus Chart, Image, Settings and Quick Access Settings. You can also start Focusing",

		description_guide_camera: "Capture and Loop",
		description_guide_status: "Guiding Status",
		description_guide_controls:
			"You can view Guide Chart, Image, Settings and Quick Access Settings. You can also start Guiding",

		description_search_filter: "Filter by metadata.",
		description_search_live: "Search by name.",
		description_feed_all: "Displays posts from all users.",
		description_feed_following: "Displays posts from users you are following.",
		description_feed_saved: "Displays bookmarked posts.",
		description_feed_add: "Add a new post.",
		description_profile_posts:
			"This tab displays your posts. Here, you can view all the posts you have created.",
		description_profile_image: "RAW images.",
		description_profile_achievements: "Achievements Tracker",
		description_observatory_map: "Public Observatories map",

		initial_tour_guide: {
			profile_general:
				"This is your Profile page where you can manage your account settings and personal information.",
			side_panel:
				"The left-hand panel is the Main Navigation. Here, you can explore Photos, connect with other Users, and view Observatories.",
			profile_page:
				"Take a look around your profile to explore the features available for managing your account.",
			profile_next:
				"Next, check out the Feed where you can explore posts from other users.",

			feed_general:
				"This is the Feed, where you can view images shared by others, see your bookmarks, and upload your own photos.",
			feed_page: "Browse posts from other users here.",
			feed_next:
				"Next, explore the Users page to find and connect with others.",

			users_general:
				"This is the Users page, where you can search for, filter, and follow other members of the community.",
			users_page: "Discover and interact with other users here.",
			users_next:
				"Next, let's visit the Equipment page to explore astronomy tools.",

			equipment_general:
				"Welcome to the Equipment page, where you can browse and learn about different astronomy equipment.",
			equipment_page:
				"Check out the astronomy equipment types. Tap any type to list all manufacturers for this equipment type, and then tap a manufacturer to list all models.",
			equipment_next:
				"Next, explore the Observatories page to view and manage observatories.",

			observatories_general:
				"Welcome to the Observatories page! Here, you can explore observatories created by other users and manage your own.",
			observatories_page: "View and manage observatories in this section.",

			final_step:
				"Congratulations! You've finished the tour. Now it's time to dive in and discover everything this platform has to offer.",
		},
	},
	tooltip: {
		placeholder: "Placeholder %{0} or %{1}",
		placeholder_file: "The name of the .esq file, without extension.",
		placeholder_date: "The current time and date when the file is saved.",
		placeholder_type: "The frame type e.g: 'Light', 'Dark'",
		placeholder_exp: "The exposure duration in seconds.",
		placeholder_exposure:
			"The exposure duration in seconds as plain number, without any unit as suffix.",
		placeholder_offset: "The offset configured for capturing.",
		placeholder_gain: "The gain configured for capturing.",
		placeholder_bin: "The binning configured for capturing.",
		placeholder_iso: "The ISO value(DSLRs only).",
		placeholder_pierside: "The current mount's pier side",
		placeholder_temperature: "The camera temperature of capturing.",
		placeholder_filter: "The active filter name.",
		placeholder_seq:
			"The image sequence identifier where * is the number of digits used (1-9), This tag is mandatory and must be the last element in the format",
		placeholder_target: "The Target name.",
		placeholder_arbitrary:
			"Arbitrary text may also be included within the Format string, except the % and / characters. The / Path character can be used to define arbitrary directories.",
		placeholder_notes: "Notes:",
		placeholder_case:
			"• Tags are case sensitive in both their short and long forms",
		placeholder_datetime:
			"• Only use the %Datetime tag in the filename portion of the format, not in the path definition.",
		format_title:
			"Format is used to define the image file names by the use of placeholder tags.",
		suffix:
			"Number of digits used to append the sequence number to the filename",
		paa_desc:
			"Use plate-solving method for Polar Alignment. Plate solving is slower but provides more accurate results.",
		plate_solving:
			"Uses plate solving to track what the corrected alignment error is during the Refresh process. User should try to reduce the error in the Updated Err line below and minimize the size of the arrows.",
		mount_info: "Move Star and Calc Error",
		movestar_desc:
			"Like Move Star, But Ekos attemps to track the star being moved and estimates the current alignment error when it can.",
		remote_description:
			"Add remote INDI drivers to chain with the local INDI server configured by this profile. Format this field as a comma-separated list of quoted driver name, host name/address and optional port:",
		remote_zwo_description:
			"Connect to the named camera on 192.168.1.50, port 8000.",
		remote_eqmod_description:
			"Connect to the named mount on 192.168.1.50, port 7624.",
		remote_port: "Connect to all drivers found on 192.168.1.50, port 8000.",
		remote_ip: "Connect to all drivers found on 192.168.1.50, port 7624.",
		remote_info:
			"When omitted, host defaults to localhost and port defaults to 7624. Remote INDI drivers must be already running for the connection to succeed.",
	},
	splash: {
		checking_for_updates: "Vérification des mises à jour...",
		downloading_package: "Téléchargement de la mise à jour...",
		installing_update: "Installation de mise à jour...",
		channel_update: "Changement de canal en cours...",
		upto_date: "Déjà à jour.",
		update_installed: "Toutes les mises à jour installées.",
		loading: "Chargement...",
	},
	validations: {
		username_required: "Le nom d'utilisateur est obligatoire",
		username_tooshort: "Minimum 3 caractères requis",
		username_toolong: "Ne peut pas contenir plus de 64 caractères",
		username_invalid: "Caractères non valides dans le nom d'utilisateur",
		password_required: "Mot de passe requis",
		password_invalid: "Minimum 6 caractères requis",
		confirm_password_required: "Confirmer le mot de passe requis",
		confirm_password_mismatch: "Confirmer le mot de passe incorrect",
		email_required: "Email (requis",
		email_invalid: "Adresse e-mail invalide",
		license_required: "Clé de licence requise",
		serial_required: "Clé de série requise",
		new_scope_vendor: "Entre un nom de fournisseur valide",
		new_scope_model_invalid: "Entre un modèle valide",
		new_scope_aperture_invalid: "Entre une ouverture valide",
		new_scope_focal_length_invalid: "Entre une focale valide",
		new_scope_focal_ratio_invalid: "Enter a valid focal ratio",
	},
	progress: {
		start_capture: "Starting capture...",
		please_wait: "S'il vous plaît, attendez ...",
		establishing_connection: "Établissement de la connexion",
		cameras: "Obtenir des caméras",
		mounts: "Obtenir des montures",
		scopes: "Obtenir des Télescopes",
		filter_wheels: "Obtenir des roues de filtre",
		registering: "Enregistrement...",
		registered: "inscrit",
		authenticating: "Authentification...",
		authenticated: "Authentifié",
		checking_kstars: "Vérification de KStars...",
		kstars_open: "KStars ouvert",
		checking_ekoslive: "Vérification d'EkosLive...",
		ekoslive_connected: "EkosLive connecté",
		starting_ekos: "Démarrer Ekos...",
		getting_devices: "Obtenir des appareils...",
		loading_settings: "Chargement des paramètres...",
		register_device: "Scanned QR Code, Registering Device: ",
	},
	welcome: {
		register_new_device: "Enregistrer un nouvel appareil?",
		have_existing_account: "Vous avez un compte existant?",
		require_sm_plus_pro: "Inscrivez-vous si vous avez",
	},
	device_scanner: {
		scanning:
			"Veuillez patienter pendant que nous recherchons des appareils StellarMate sur le réseau",
		qr_scan: "Scannez le QR Code de votre appareil ",
	},
	statuses: {
		Idle: "Inactif",
		prep: "Prep",
		run: "Run",
		Aborted: "Avorté",
		"Calibration error": "Erreur d'étalonnage",
		Capturing: "Capturer",
		"In Progress": "En cours",
		"Setting Temperature": "Réglage de la température",
		Slewing: "Pivotement",
		Calibrating: "Étalonnage",
		Tracking: "Poursuite",
		Guiding: "Guidage",
		Parking: "Parking",
		"User Input": "Entrée utilisateur",
		Complete: "Compléter",
		Suspended: "Suspendu",
		Parked: "Parquée",
	},
	connect: {
		register_welcome:
			"Veuillez vous connecter à votre compte stellarmate.com pour synchroniser les appareils.",
		welcome_heading: "Salut!",
		welcome_description:
			"Veuillez vous assurer que vous êtes connecté au HotSpot de StellarMate ou que StellarMate est sur le même réseau que vous.",
		welcome_rescan:
			"Cliquez sur RESCAN pour commencer à scanner le réseau pour les appareils StellarMate.",
		device_unreachable:
			"L'appareil n'est pas accessible! Vérifiez les paramètres d'alimentation et de réseau.",
		login_mismatch:
			"Authentification échouée. Le mot de passe de l'application est différent du mot de passe en ligne de stellarmate.com. Enregistrez à nouveau l'application avec le mot de passe en ligne correct.",
		register_using_key: "Register Device using Serial number",
		old_stellarmate_heading: "Mise à jour requise!",
		old_stellarmate_description:
			"Vous semblez utiliser une ancienne version de StellarMate OS. Vous devez passer à la version la plus récente de StellarMate pour continuer à utiliser cette application.",
		sm_app_update_title: "SM App Update Required!",
		sm_app_update_body:
			"You appear to be using an older version of StellarMate App. Please update to the latest version.",
		primary: "Primaire",
		guide: "Guider",
		scope: "Portée",
		btn_rescan: "RESCAN",
		btn_port_select: "Selection de port",
		btn_sync_gps: "Synchroniser le GPS",
		btn_dslr_setup: "Configuration DSLR",
		btn_clear_driver_config: "Effacer la configuration du pilote.",
		scan_in_progress: "Analyse en cours ...",
		connection_in_progress: "Connexion de StellarMate ...",
		registration_in_progress: "Enregistrement de StellarMate ...",
		logging_in_progress: "Connexion à StellarMate ...",
		generic: "Generic Serial",
		select_driver: "Please select device type and driver",
		invalid_ip: "No IP address found or invalid IP {0}. Please try again.",

		cloudsMap: {
			btn_clouds_map: "Clouds Map",
			attribution: "OpenStreetMap",
			map_title: "3-Hour Cloud Map",
			bortle_class: "Bortle Class",
		},

		connecting: "De liaison...",
		logging: "Enregistrement...",
		ip_address: "Adresse IP",
		login_register: {
			heading: "Authentifier",
			heading_online: "Connectez-vous à stellarmate.com",
			connect_to_internet: "Vous devez être connecté à Internet.",
			connect_to_sync:
				"Cela n'est fait qu'une seule fois pour synchroniser votre compte.",
			reset_app:
				"Conseil: vous pouvez resynchroniser l'application avec votre compte en ligne en accédant à l'onglet À propos et en cliquant sur Bouton de réinitialisation de l'application, puis relance de l'application",
			no_valid_device: "Aucune information valide sur l'appareil disponible.",
			setup_guide: "Guide d'installation",
			register: "S'inscrire",
			login: "Se connecter",
			serial: "Numéro de série",
			license: "Clé de licence",
			username: "Nom d'utilisateur",
			password: "Mot de passe",
			confirm_password: "Confirmez le mot de passe",
			first_name: "Prénom",
			last_name: "Nom",
			email: "Email",
			manually: "Manually",
		},
		device_manager: {
			alert_confirm_remove_title: "Confirmer la suppression",
			alert_confirm_remove_body: "Voulez-vous vraiment supprimer cet appareil?",
			btn_sign_out: "Déconnexion",
		},
		profile_manager: {
			heading: "Profils d'équipement",
		},
		port_selector: {
			connect_all: "Tout connecter",
		},
		manually_add_device: {
			heading: "Ajouter manuellement un appareil",
			btn_add_device: "Ajouter un appareil",
			alert_unreachable_title: "Une erreur s'est produite",
			alert_unreachable_body:
				"Une erreur s'est produite lors de la tentative de localisation du périphérique à l'adresse IP spécifiée. Veuillez vérifier à nouveau l'adresse IP et réessayer.",
		},
		device_scanner: {
			no_device_before_scan: "Aucun appareil détecté. Exécutez le scanner.",
			no_device_after_scan: "Analyse terminée. Aucun périphérique trouvé.",
			auto_scanned: "Analyse automatique",
			manually_added: "Ajouté manuellement",
			add_a_device: "Ajouter un périphérique",
			devices_detected: "Appareil détecté",
			no_network_found:
				"Aucun réseau détecté. Assurez-vous que vous êtes connecté à un réseau.",
		},
		add_profile: {
			add_profile: "Ajouter un profil",
			edit_profile: "Editer le profil",
			mount: "Monture",
			ccd: "Camera 1",
			dome: "Dome",
			focuser: "Focuser",
			filter: "Filtre",
			guider: "Camera 2",
			ao: "Optique adaptative",
			weather: "Météo",
			aux1: "Aux1",
			aux2: "Aux2",
			aux3: "Aux3",
			aux4: "Aux4",
			indi_server: "INDI Server",
			local: "Local",
			host: "Host",
			web_manager: "INDI Web Manager",
			profile_settings: "Profile Settings",
			auto_connect: "Auto Connect",
			port_selector: "Port Selector",
			usb_reset: "Force USB Reset",
			remote_drivers: "Remote Drivers",
		},
		add_scope: {
			add_scope: "Ajouter un télescope",
			edit_scope: "Modifier le télescope",
			vendor: "Fabricant",
			aperture: "Ouverture (mm)",
			focal_length: "Focale (mm)",
		},
		auto_detect: {
			alert_auto_detect_title: "Instructions de détection automatique",
			alert_auto_detect_body:
				"Débranchez TOUS les équipements de StellarMate puis appuyez sur Ok. Puis branchez-les un par un pour détecter le type de périphérique et le pilote. Une fois que chaque périphérique est branché, vous devez confirmer le pilote.",
			alert_mapped_title: "Cartographie des appareils",
			alert_mapped_body: "Le port série de l'appareil est correctement mappé.",
			alert_missing_driver_title: "Pilote manquant",
			alert_missing_driver_body: "Vous devez d'abord sélectionner un pilote.",
		},
		dslr_setup: {
			width: "Largeur",
			height: "la taille",
			pixel_width: "Largeur pixel",
			pixel_height: "Hauteur pixel",
		},
		observatory: {
			observatory_name: "Name of the observatory",
			bortle_scale: "Bortle Scale",
			observatory_delete_submit:
				"Are you sure you want to delete the observatory? All equipment and the equipment profiles will also be deleted",
			observatory_delete_title: "Delete observatory",
			empty_profile:
				"The selected profile currently has no equipment. To proceed, please add new equipment.",
			empty_profiles_list:
				"The selected observatory currently has no equipment profiles. To proceed, please add new profile.",
			manufacturer: "Manufacturer",
			profile_name: "Profile Name",
			no_profiles_found:
				"The selected observatory currently has no equipment profiles.",
			no_equipment_found: "No equipment found for this profile",
			synchronized_profile:
				"This profile is already synchronized with a KStar Profile.",
			map_to_existing_profile: "Map to existing KStars Profile",
			map_to_new_profile: "Map to new KStars Profile",
			select_kstars_profile: "Select KStars Profile",
			warning_equipment_mismatch: "Warning: Equipment mismatch detected",
			indi_driver: "INDI Driver",
			kstars_profile: "KStars Profile",
			not_connected_to_kstars:
				"No equipment profiles detected. Please ensure KStars is connected to EkosLive.",
		},
		no_connected_instances:
			"No active instances detected, please make sure KStars is connected and is not linked to any other observatory.",
		observatories: "Observatories",
		equipment: "Equipment",
		observatory_delete_submit: "Observatory successfully deleted",
	},
	targets: {
		now: "Maintenant",
		night: "Nuit",
		rise: "Lever",
		moon: "Lune",
		sun: "Soleil",
		search: "Chercher",
		cam_width: "Camera Width",
		cam_height: "Camera Height",
		fov_warning: "FOV is too small or large, Please check!",
		phases: {
			new_moon: "Nouvelle Lune",
			full_moon: "Pleine Lune",
			first_quarter: "Premier Quartier",
			third_quarter: "Dernier Quartier",
			waxing_crescent: "Premier croissant",
			waxing_gibbous: "Gibbeuse croissante",
			waning_crescent: "Dernier croissant",
			waning_gibbous: "Gibbeuse décroissante",
		},
		lists: "Liste",
		framing_assistant: "Framing Assistant",
		target_rotation: "Target Position Angle",
		current_rotation: "Current Rotation",
		rotate_capture: "Rotation & Capture",
		goto_rotate: "GOTO & Rotation",
		angular_offset: "Angular Offset",
		no_objects_in_list:
			"No Objects found. Please check active list, adjust or reset the filters.",
		go_and_solve: "Go & Solve",
		fov_profile: "FOV Profile",
		fov_width: "FOV Largeur",
		fov_height: "FOV Hauteur",
		alert_select_FOV_body:
			"Please create or select an FOV profile in order to use Framing assistant.",
		alert_list_exists_body: "A list with that name already exists",
	},
	ekos: {
		heading: "Ekos",
		tgl_mount: "MONTURE",
		tgl_solution_bar: "BARRE DE SOLUTION",
		tgl_sequence: "SÉQUENCE",
		tgl_properties: "PROPRIÉTÉS",
		alert_ekos_offline_title: "Ekos est hors ligne",
		alert_ekos_offline_body:
			"Ekos semble être hors ligne pour le moment. Avez-vous commencé le profil d'équipement?",
		ekos_dialog: {
			auto_closes_in: "Fermeture automatique dans",
		},
		indi: {
			no_logs: "No logs are available for this driver",
		},
		controls_bar: {
			mount_speed: "Vitesse de la monture",
			centering: "Centrage",
			find: "Trouver",
			max: "Max",
			parking_position: "Parking Position is set successfully.",
		},
		collapse_align: {
			heading: "Aligner",
			action_sync: "Synchroniser",
			action_slew: "Viser vers la cible",
			action_nothing: "Rien",
			solver_backend: "Backend",
			control: "Contrôle",
			solve: "Capturer et résoudre",
			load: "Charger et faire pivoter",
			polar: "Alignement Polaire",
			accuracy: "Précision",
			settle: "Régler",
			dark: "Dark",
			arcsec: "arcsec",
			ms: "SP",
			x_axis: "Itérations",
			y_axis: "Erreur (arcsec)",
			refresh_rate: "Taux de rafraichissement",
			image_selected: "Image selected successfully",
			select_method: "Please select the image selection method",
			device_gallery: "Phone/Tablet gallery",
			sm_storage: "SM Storage",
			request_storage_permission: "Please allow the storage permission",
			celestial_warning:
				"Plate solving does not work very close to the celestial pole.",
			manualRotator: {
				heading: "Rotation Manuelle",
				current_pa: "PA Actuel",
				target_pa: "PA Cible",
				another_image: "Prendre une autre Image",
			},
			align_settings: {
				rotator_control: "Contôle Rotateur",
				use_scale: "Utiliser Echelle",
				use_position: "Utiliser Position",
			},
			calibration_settings: {
				pulse: "Pulse",
				max_move: "Max Move",
				iterations: "Iterations",
				two_axis: "Two axis",
				square_size: "Auto square size",
				calibrate_backlast: "Remove DEC backlash in guide calibration",
				reset_calibration: "Reset Guide Calibration After Each Mount Slew",
				reuse_calibration: "Store and reuse guide calibration when possible",
				reverse_calibration:
					"Reverse DEC on pier-side change when reusing calibration",
				skyflats: "Sky flats",
			},
		},
		collapse_camera: {
			heading: "Capturer",
			type_light: "Brutes",
			type_bias: "Biais",
			type_flat: "Flat",
			type_dark: "Dark",
			format_fits: "Fits",
			format_native: "Natif",
			cooling_unavailable: "N/A",
			btn_add_to_sequence: "Ajouter à la séquence ",
			btn_loop: "Boucle",

			rotator_control: {
				title: "Rotator",
				angle: "Rotator Angle",
				offset: "Camera Offset",
				pierside: "Camera Pierside",
				flip: "Flip Policy",
				pos_angle: "Camera Position Angle",
				reverse_direction: "Reverse direction of Rotator",
				flip_rotator: "Preserve Rotator Angel",
				flip_position: "Preserve Position Angel",
			},

			capture_settings: {
				miscellaneous: "Miscellaneous",
				temperature: "Temperature threshold",
				temperature_tooltip:
					"Maximum acceptable difference between requested and measured temperature set point. When the temperature threshold is below this value, the temperature set point request is deemed successful.",
				guiding: "Guiding settle",
				guiding_tooltip:
					"Wait this many seconds after guiding is resumed to stabilize the guiding performance before capture.",
				dialog: "Dialog timeout",
				dialog_tooltip: "Cover or uncover telescope dialog timeout in seconds.",

				reset_sequence: "Always reset sequence when starting",
				reset_sequence_tooltip:
					"When starting to process a sequence list, reset all capture counts to zero. Scheduler overrides this option when Remember job progress is enabled.",
				reset_mount: "Reset mount model after meridian flip",
				reset_mount_tooltip: "Reset mount model after meridian flip.",
				use_flip: "Use flip command if supported by mount",
				use_flip_tooltip: "Use flip command if it is supported by the mount.",
				summary_preview: "Summary screen preivew",
				summary_preview_tooltip:
					"Display received FITS in the Summary screen preview window.",
				operations_timeout: "Capture Operations Timeout",
				operations_timeout_tooltip:
					"Maximum number of seconds to wait before aborting the capture if operations like filter wheel changes or meridian flips take too long",

				force_dslr: "Force DSLR presets",
				image_viewer: "DSLR image viewer",

				sequence_focus: "In-Sequence Focus",
				hfr_threshold: "HFR threshold modifier",
				hfr_threshold_tooltip:
					"Set HFR Threshold percentage gain. When an autofocus operation is completed, the autofocus HFR value is increased by this threshold percentage value and stored within the capture module. If In- Sequence-Focus is engaged, the autofocus module only performs auto-focusing procedure if current HFR value exceeds the capture module HFR threshold. Increase value to permit more relaxed changes in HFR values without requiring a full autofocus run.",
				sequence_check: "In-sequence HFR check",
				sequence_check_tooltip:
					"Run In-Sequence HFR check after this many frames.",

				median: "Use median focus",
				median_tooltip:
					"Calculate median focus value after each autofocus operation is complete. If the autofocus results become progressively worse with time, the median value shall reflect this trend and prevent unnecessary autofocus operations when the seeing conditions deteriorate.",
				save_sequence: "Save sequence HFR value to file",
				save_sequence_tooltip:
					"In-sequence HFR threshold value controls when the autofocus process is started. If the measured HFR value exceeds the HFR threshold, autofocus process is initiated. If the HFR threshold value is zero initially (default), then the autofocus process best HFR value is used to set the new HFR threshold, after applying the HFR threshold modifier percentage. This new HFR threshold is then used for subsequent In-Sequence focus checks. If this option is enabled, the HFR threshold value is constant and gets saved to the sequence file.",
			},
		},
		capture_presets: {
			heading: "Paramètres prédéfinis",
		},
		capture_limits: {
			heading: "Limiter les paramètres",
			guiding_deviation: "Écart de guidage <",
			guiding_deviation_unit: '"',
			focus_hfr: "Autofocus si HFR>",
			focus_hfr_unit: "pixels",
			focus_deltaT: "Autofocus si ΔT °>",
			focus_deltaT_unit: "°C",
			refocus_n: "Recentrer chaque",
			refocus_n_unit: "minutes",
			refocus_on_hfr: "Refocus on HFR. Use",
			refocus_meridian: "Refocus after meridian flip",
			check_every: "Check every",
			about_guide_deviation: "About if guide deviation >",
			start_deviation: "Only start if guide deviation <",
			guide_deviation: "Guide deviation",
			consecutive_times: "consecutive times",
			dither_job: "Dither per job every",
		},
		capture_filters: {
			heading: "Paramètres de filtre",
			auto_focus: "Mise au point automatique",
			lock_filter: "Verrouiller le filtre",
			no_filters: "No filters have been found.",
		},
		targets_filters: {
			object_type: "Type d'objet",
			alt: "Alt",
		},
		capture_auto_calibration: {
			heading: "Calibration automatique",
			flat_source: "Source de Flat",
			flat_duration: "Durée Flat",
			dust_builtin: "Cache-poussière avec éclairage plat intégré",
			dust_external: "Cache-poussière avec éclairage plat externe",
			wall: "Mur",
			az: "Az",
			adu: "ADU",
			tolerance: "Tolérance",
			park_mount: "Monture parquée",
			park_dome: "Dôme fermé",
			manual: "Manuel",
			pre_actions: "Calibration Pre-Actions",
		},
		capture_file: {
			filename: "Nom du Fichier",
			heading: "Paramètres de fichier",
			prefix: "Préfixe",
			script: "Scénario",
			directory: "répertoire",
			ts: "TS",
			duration: "Durée",
			suffix: "Suffix",
			usb: "USB Unmounted successfully",
			unmount: "Unmount",
		},
		collapse_dome: {
			heading: "Coiffe et Dôme",
			cap: "Coiffe",
			dome: "Dôme",
			shutter: "Obturateur",
			dome_slaving: "Asservissement du dôme",
			params: "Paramètres",
			measurements: "Mesures",
			autosync_threshold: "Seuil de synchronisation automatique (deg)",
			radius: "Rayon (m)",
			shutter_width: "Largeur d'obturation (m)",
			n_displacement: "N Déplacement (m)",
			e_displacement: "Déplacement E (m)",
			up_displacement: "Déplacement vers le haut (m)",
			ota_offset: "Décalage OTA (m)",
		},
		collapse_focus: {
			heading: "MAP-FOCUS",
			hfr: "HFR",
			hfr_plot: "Tracé HFR",
			focus_in: "MAP vers l'intérieur",
			focus_out: "MAP vers l'extérieur",
			steps: "Pas",
			advisor: "Focus Advisor",
			update_parameters:
				"Optimize the Focus parameters depending on your current camera and telescope.",
			find_stars:
				"Check to have Focus Advisor search for stars. If you are close enough to Focus to see stars in the Focus frame then this step is not neccessary.",
			coarse_adjustment:
				"Check to have Focus Advisor find coarse focus. This will find an approximate focus position good enough to run Autofocus.",
			fine_adjustment:
				"Check to have Focus Advisor run Autofocus to make fine adjustments to focus parameters.",
			settings: {
				suspend_guiding: "Suspend Guiding",
				auto_select_star: "Auto Select Star",
				subframe: "Sub Frame",
				fullfield: "Full Field",
				dark: "Dark Frame",
				annulus: "Annulus",
				mask: "Mask",
				stars: "Use all stars for focusing",
				ring: "Ring Mask",
				adaptive: "Adaptive",
				min_move: "Min. Move",
				start: "Adap Start Pos",
				max_move: "Max Total Move",
			},
			process: {
				title: "Traiter",
				detection: "Détection",
				algorithm: "Algorithme",
				threshold: "Seuil",
				effect: "Effet",
				average_over: "Moyenne sur",
				kernel_size: "Taille du noyau",
				num_of_rows: "Nombre",
				sigma: "Sigma",
				curve_fit: "Curve Fit",
				limit: "Limit",
				average_hfr: "Average HFR Check",
				donut_buster: "Donut Buster",
				scan_pos: "Scan for Start Position",
				datapoints: "Num datapoints",
				initial_size: "Initial Step Size x",
				use_weights: "Use Weights",
				refine_curve: "Refine Curve Fit",
				time_dilation: "Time Dilation x",
				outlier_rejection: "Outlier Rejection",
			},
			donut_buster: {
				title: "Donut Buster",
				always_on: "Always on",
			},
			mechanics: {
				title: "Mécanique",
				initial_step_size: "Taille initiale du pas",
				max_travel: "Trajet maximum",
				max_step_size: "Taille maximale du pas",
				backlash: "Backlash",
				out_step_multiple: "délai déplacement dépassé",
				number_steps: "Number Steps",
				af_overscan: "AF Overscan",
				capture_timeout: "Capture timeout",
				motion_timeout: "Motion timeout",
				overscan_delay: "Overscan Delay",
			},
		},
		collapse_guide: {
			total_rms_plot: "RMS",
			scope_primary: "Primaire",
			guidingRate: "Taux de guidage",
			via: "Via",
			box: "Boîte",
			effects: "Effets",
			dither: "Décalage",
			frequency: "Fréquence",
			every: "every",
			frames: "frames",
			gpg: "GPG",
			directions: "Directions",
			dec: "DÉC",
			swap: "Échanger",
			east: "+",
			west: "-",
			north: "+",
			south: "-",
			subframe: "Sous trame",
			autostar: "Auto Star",
			aggressiveness: "Gain prop.",
			rarms: 'RA RMS"',
			derms: 'DE RMS"',
			totalrms: 'Total RMS"',
			advanced: "Avancé",
			clear_model: "Effacer le modèle d'étalonnage",
			yAxis: "Dérive (arcsec)",

			min_error: "Min error",
			max_response: "Max response",
		},
		collapse_observatory: {
			heading: "Observatoire",
		},
		collapse_polar: {
			heading: "Alignement polaire",
			mount_direction: "Direction de la monture",
			manual_slew: "Déplacement Manuel",
			west: "Ouest",
			east: "Est",
			mount_rotation: "Rotation de la Monture",
			refresh: "Rafraîchir",
			fov_error:
				"Désactivé: le champ de vision doit être de 30 arcmins ou plus.",
			polar_error: "Erreur polaire :",
			az_error: "AZ error: ",
			al_error: "AL error: ",
		},
		collapse_sequence: {
			progress: "Progression",
			sequence: "Séquence",
			overall_progress: "Progression Globale",
			sequences: "Séquences",
			no_sequences:
				"Pas de séquences. Vous pouvez les ajouter en appuyant sur Ajouter à la séquence.",
			alert_sequence_error_body: "Error saving sequence file",
			alert_sequence_loading_body: "Error loading sequence file",
			alert_sequence_delete_body: "Error deleting sequence file",
		},
		collapse_mount: {
			meridian_flip: "Retournement au Méridien",
			flip_if_ha: "Retourner si HA>",
			flip_if_ha_unit: "°",
			pierside: "Côté de la jetée",
			auto_park: "Parking automatique",
			auto_park_everyday: "Tous les jours",
			park_at: "Parquer à",
			reset_options: "Réinitialiser les options",
			min_alt: "Alt min",
			max_alt: "Alt max",
			enable_alt_limits: "Activer les limites Alt",
			enable_ha_limits: "Activer les limites HA",
			max_ha: "HA max (heures)",
			clear_model: "Purger Modèle",
			east: "E",
			west: "O",
			east_long: "Est (pointant vers l'ouest)",
			west_long: "Ouest (pointant vers l'est)",
			btn_set_auto_park_timer: "Définir l'heure de parquage automatique",
			reverse_NS: "Reverse North/South",
			reverse_WE: "Reverse West/East",
			time_source: "Time source",
			location_source: "Location source",
			enter_coordinates: "Enter Coordinates",
			select_target: "Select Target",
		},
		modal_add_to_seq: {
			heading: "Ajouter à la séquence",
		},
		modal_goto_sync: {
			heading: "Aller ou synchroniser",
			category: "Catégorie",
		},
		mount_status_bar: {
			ra: "RA",
			de: "DE",
			az: "AZ",
			at: "AL",
			ha: "HA",
		},
		camera_status_bar: {
			bin: "Bin",
		},
		solution_bar: {
			dra: "dRA",
			dde: "dDE",
			arcsec: '"',
			pix: "PIX",
			rot: "ROT",
			fov: "FOV",
			error: "Erreur",
		},
		mount_overlay: {
			right_asc: "Asc. Droit.",
			declination: "Déclinaison",
		},
		histogram: {
			mean: "Mean",
			median: "Median",
			minimum: "Min",
			maximum: "Max",
			bit_depth: "Bit Depth",
			non_linear_histogram: "Histogramme Non-Lineaire",
		},
		livestacking: {
			auto_dark: "Auto Dark",
			plate_solve: "Plate Résoudre",
			background: "Background",
			saturation: "Saturation",
			contrast: "Contraste",
			sigma_clipping: "Sigma Clipping",
			noImages: "Pas d'images trouvées pour Live Stacking",
			median_filter: "Median Filter",
		},
		scheduler: {
			scheduler: "PLANIFICATEUR",
			j2000: "J2000",
			add_sequences: "Add Sequences",
			fits: "FITS File",
			priority: "Priorité",
			profile: "Profil",
			startupConditions: "démarrage",
			JobConstraints: "Contraintes  des Tâches",
			completionConditions: "de fin des Tâches",
			observatoryStartup: "de démarrage de l'Observation",
			abortedJobManagement: "Gestion des tâches abandonnées",
			observatoryShutdown: "Arrêt Observation",
			alt: "Alt >",
			moon: "Moon min. sep",
			moon_max: "Moon max alt",
			twilight: "Aube",
			artifHorizon: "Horizon Artificiel",
			sequenceCompletion: "Achèvement des Sequences",
			repeatFor: "Répéter pour",
			repeatUntilTerminated: "Répéter jusqu'à terminaison",
			repeatUntil: "Répéter jusqu'à",
			unparkDome: "Déparduer le Dôme",
			unparkMount: "Déparquer la Monture",
			uncap: "Enlever la coiffe",
			warmCCD: "Réchauffer CCD",
			none: "None",
			queue: "Queue",
			immediate: "Immediate",
			rescheduleErrors: "Reschedule Errors",
			no_jobs: "Pas de Tâche dans la file d'attente",
			err_loading_folders: "Error loading folders",
			err_loading_fits: "Error loading .fits files",
			err_delete_file: "Error deleting file",
			add_job: "Add Job",
			start_jobs: "Start & Stop Jobs",
			culimination: "Culmination Offset",
			create_jobs: "Create Jobs",

			mosaic: {
				import: "Import Mosaic",
				planner: "Mosaic planner",
				mask: "Mosaic Mask",
			},

			scheduler_settings: {
				lead_time: "Lead time",
				lead_time_tooltip:
					"The minimum time in minutes between jobs. The scheduler starts execution of a job before its scheduled startup time by this lead time. Early execution is useful as focusing, alignment, and guiding procedures may take prolonged periods to time to complete.",
				pre_dawn: "Pre-dawn",
				pre_dawn_tooltip:
					"Do not permit jobs to be scheduled or executed past this many minutes before dawn.",
				pre_emptive: "Pre-emptive shutdown",
				pre_emptive_tooltip:
					"In case no scheduler job is scheduled for this many hours, perform a complete shutdown procedure and restart observatory operations once the next job is ready.",
				setting_altitude: "Setting altitude cutoff",
				setting_altitude_tooltip:
					"Do not permit jobs to be scheduled less than this many degrees before the altitude restriction. Actual execution proceeds until the altitude limit.",
				weather_grace: "Weather grace period",
				weather_grace_tooltip:
					"Enable Weather-Based Scheduling: Prevents new jobs from starting during weather warnings or alerts. Existing jobs continue during warnings, but are aborted during alerts. System initiates a soft shutdown and waits for improved conditions or grace period expiration, after which the current job is terminated.",
				dusk_offset: "Dusk offset",
				dusk_offset_tooltip:
					"Offset astronomical dusk by this many hours. This positive or negative value adjusts the twilight restriction.",
				dawn_offset: "Dawn offset",
				dawn_offset_tooltip:
					"Offset astronomical dawn by this many hours. This positive or negative value adjusts the twilight restriction.",

				stop_ekos: "Stop Ekos after shutdown",
				stop_ekos_tooltip:
					"After shutdown procedure is successfully executed, stop INDI and Ekos.",
				shutdown_script: "Shutdown script terminates INDI",
				shutdown_script_tooltip:
					"If the shutdown script terminates INDI server, enable this option so that no disconnection errors are generated.",
				remember_job: "Remember Job progress",
				remember_job_tooltip:
					"When processing a scheduled job, resume the sequence starting from the last image present in storage.",

				execute_job: "Always execute startup job",
				execute_job_tooltip:
					"Observatory startup script is normally only executed if Ekos is offline. Enable this option to start it whenever scheduler is starting up.",

				greedy_schedule: "Use Greedy Scheduling",
				greedy_schedule_tooltip:
					"When checked the scheduler tries to run lower priority jobs when no higher priority job can run. Recommended.",
				reset_mount: "Reset mount model on alignment failure",
				reset_mount_tooltip: "Reset mount model on alignment failure",
				reset_mount_before: "Reset mount model before starting each job",
				reset_mount_before_tooltip:
					"Reset mount model before starting each job",
				force_realign: "Force re-alignment before re-starting jobs",
				force_realign_tooltip:
					"If Align is enabled, scheduler would initiate a realignment procedure before restarting any jobs even if guiding is active.",
				restart_align: "Restart alignment on guiding calibration failure",
				restart_align_tooltip:
					"If guiding calibration fails then restart alignment process before proceeding to guiding recalibration process again. This can help recenter the target object in the field of view if the calibration process strayed too far off.",

				offsets: "Offsets",
				clean_jobs: "Scripts and Jobs",
				alignment: "Alignment",

				verify_image: "Verify captured image position every",
				verify_image_tooltip:
					"When calculating position after captures, compute it every Nth capture. Set to 0 to disable.",
				reset_pipeline: "Reset pipeline if verified image delta exceeds",
				reset_pipeline_tooltip:
					"If captured position exceeds target position by more this many arcminutes, abort capture and reschedule the pipeline.",
				arcminutes: "arcminutes",
			},
		},
		summary: {
			empty: "No summary data available",
			deviation: "Deviation",
			inactive: "Inactive",
		},
	},
	optical_train: {
		optical_trains: "Optical Trains",
		telescope: "Telescope / Lens",
		create_new: "Create New",
		dslr_lens: "DSLR Lens",
		reset_train: "Reset Train",
		focal_ratio: "Focal Ratio",
		guide_via: "Guide Via",
		tooltip: {
			title: "Select the device that receives the guiding correction pulses",
			subject: "This can be one of the following devices:",
			mount_device:
				"If the Mount can receive guiding correction pulses, then it is best to select it so that the pulses are directly sent to the mount. This is the recommended option.",
			st4_device:
				"If using an ST4 cable between the guide camera and mount, then you should select the Guide Camera as the Guider.",
			dedicated_device:
				"If using a dedicated guider interface device (like Shoestring GPUSB) then select it as the Guider",
			dedicated: "Dedicated",
			filterWheel:
				"Select a filter wheel. This must be a standalone filter wheel connected to StellarMate via USB. If you have a filter wheel that is embedded in a camera, leave this field empty.",
		},
	},
	status: {
		system: "Système",
		info: "Info",
		software: "Logiciel",
		cpu: "CPU",
		ram: "RAM",
		storage: "ESPACE DE STOCKAGE",
		stable: "Stable",
		beta: "Bêta",
		wifi_network: "IP du réseau Wifi",
		ethernet_network: "IP Ethernet",
		model: "Modèle",
		version: "Version",
		hostname: "Nom de l'hôte",
		time: "Temps",
		release_type: "Type de version",
		updates: "Mises à jour",
		hotspotMode: "Mode point d'accès",
		wifiNetwork: "Réseau Wifi",
		wifiCountry: "WiFi Country",

		wifiBand: "Bande WiFi",
		update_available: "Nouvelles mises à jour disponibles",
		btn_vnc: "Bureau (VNC)",
		btn_web_manager: "Gestionnaire Web",
		btn_restart: "Redémarrer",
		btn_shutdown: "Eteindre",
		btn_manage_wifi: "Gérer le WiFi",
		btn_enable_direct_ethernet: "Activer Direct Ethernet",
		btn_disable_direct_ethernet: "Désactiver Direct Ethernet",
		btn_enable_hotspot: "Activer le point d'accès",
		btn_disable_hotspot: "Désactiver le point d'accès",
		btn_change_hostname: "Changer le nom d'hôte",
		btn_check_for_updates: "Rechercher des mises à jour",
		btn_update_now: "Mettez à jour maintenant",
		btn_update_in_progress: "Mise à jour...",
		btn_factory_reset: "Retour aux paramètres d'usine",
		btn_change_resolution: "Changer la résolution",
		btn_later: "Plus tard",
		btn_resize_fs: "Redimensionner SD",
		btn_continue: "Continuer",
		information_ekos_offline:
			"Ekos est hors ligne. Please start the equipment profile directly or via the Scheduler.",
		no_camera_detected: "Pas de caméra détectée ou Caméra hors ligne.",

		alert_device_offline_title: "L'appareil est hors ligne",
		alert_device_offline_body:
			"L'appareil semble être hors ligne pour le moment. Vous êtes-vous connecté à un appareil?",
		alert_restart_title: "Redémarrer StellarMate",
		alert_restart_body:
			"Votre appareil va maintenant être redémarré. Vous pouvez essayer de vous reconnecter une fois qu'il a redémarré.",
		alert_shutdown_title: "Arrêter StellarMate",
		alert_shutdown_body:
			"Votre appareil va maintenant s'éteindre. Pour pouvoir le réutiliser, vous devrez l'activer manuellement.",
		alert_resize_title: "Redimensionner la carte microSD",
		alert_resize_body:
			"StellarMate s'étendra à la taille complète de la carte SD. Redémarrez StellarMate après le redimensionnement.",
		alert_resize_btn_resize: "Redimensionner",
		alert_hotspot_enable_title: "Activation du point d'accès",
		alert_hotspot_enable_body:
			"Si vous activez le point d'accès, stellarmate se déconnectera de tout réseau wifi actuellement connecté et démarrera son propre point d'accès. Pour continuer à utiliser StellarMate, vous devez vous connecter au même réseau hotspot depuis votre appareil. Voulez-vous continuer?",
		alert_hotspot_post_enable_title: "Veuillez vous connecter au hotspot",
		alert_hotspot_post_enable_body:
			"Vous pouvez maintenant vous connecter au hotspot «stellarmate» et appuyer sur OK lorsque vous avez terminé pour continuer",
		alert_hotspot_disable_body:
			"La désactivation du hotspot permettra à StellarMate de se connecter à son réseau sans fil précédemment mémorisé. Vous devez être sur le même réseau pour continuer à utiliser StellarMate lorsque son hotspot est désactivé. Voulez-vous continuer?",
		alert_factory_reset_body:
			"Réinitialisation d'usine terminée. Veuillez redémarrer StellarMate maintenant.",
		alert_factory_reset_confirmation_body:
			"Voulez-vous vraiment réinitialiser l'appareil aux paramètres d'usine? Tous les paramètres seront effacés. Cette action est irréversible!",
		alert_forget_wifi_title: "Oubliez le Wifi",
		alert_forget_wifi_body:
			"Tous les réseaux WiFi seront effacés et StellarMate fonctionnera désormais en mode HotSpot. Vous devez basculer vers le réseau hotspot de votre téléphone / tablette. Voulez-vous continuer?",
		alert_join_wifi_title: "Rejoignez le réseau Wifi",
		alert_join_wifi_body:
			"Rejoindre un réseau wifi fermera le hotspot (s'il est démarré) par StellarMate. Pour continuer à utiliser StellarMate, assurez-vous que vous êtes sur le même réseau. Voulez-vous continuer?",
		alert_change_band_title: "Êtes-vous sûr de vouloir changer de groupe?",
		alert_change_band_body:
			"L'application StellarMate peut être déconnectée et le changement de canal peut échouer pour diverses raisons, auquel cas le point d'accès sera restauré aux paramètres par défaut.",
		alert_change_resolution_title: "Succès!",
		alert_change_resolution_body:
			"Vous pouvez maintenant redémarrer StellarMate pour que la résolution prenne effet",
		alert_change_release_title: "Chaîne bêta",
		alert_change_release_body:
			"Voulez-vous vraiment passer à la version bêta instable et non compatible?",
		alert_direct_ethernet_title: "Ethernet direct",
		alert_direct_ethernet_connect_body:
			"Connectez le câble Ethernet directement entre StellarMate et votre PC. Définissez l'adresse IP de votre PC sur 192.168.100.2 et la passerelle sur 192.168.100.1",
		alert_direct_ethernet_disconnect_body: "Retirez le câble Ethernet direct.",
		alert_clear_all_driver_config_title:
			"Effacer toute la configuration du pilote",
		alert_clear_all_driver_config_body:
			"Êtes-vous sûr de vouloir supprimer toute la configuration du pilote?",
		alert_update_complete_title: "SM OS Update",
		alert_update_complete_body: "Update complete, please restart StellarMate.",
		alert_network_error_title: "Network error",
		alert_network_error_body: "Failed to query network information",
		alert_delete_directory_message:
			"You want to delete {0} directory and all files under it?",

		alert_permission_title: "Location permission",
		alert_permission_description:
			"StellarMate App requires Location information to synchronize StellarMate gadget location to the App location. Do you want to proceed?",

		alert_remote_isactive:
			"Failed to check if Remote support is active, Is your Stellarmate OS up to date? ",
		alert_remote_start: "Could not start the Remote support: ",
		alert_remote_stop: "Could not stop the Remote support: ",
		alert_remote_id: "Could not find Remote support ID",
		alert_remote_failed: "Failed to get Remote support ID: ",
		alert_remote_not_found: "No Remote support found: ",
		alert_permission_denied: "Permission Denied",
		alert_permission_blocked: "Permission Blocked",
		alert_grant_camera_permission: "You need to grant camera permission first",
		alert_feature_not_available: "This feature is not available",

		alert_logout_account_body: "Are you sure you want to logout?",
		alert_reset_ekoslive_title: "Reset Ekoslive",
		alert_reset_ekoslive_body:
			"Are you sure you want to reset Ekoslive? This would delete all cached images and restarts the server.",
		alert_download_index_file:
			"Astrometry Index files are missing. Would you like to download?",
		alert_low_space:
			"Your device is running out of space. Please delete unnecessary files.",

		change_resolution_modal: {
			mode: "Mode",
		},
		change_hostname_modal: {
			new_hostname: "Nouveau nom d'hôte",
		},
		change_wifi_modal: {
			heading: "Rejoignez un réseau",
			ssid: "SSID",
			err_wifi:
				"Un problème est survenu lors de la recherche de réseaux Wi-Fi.",
		},
		change_band_modal: {
			heading: "Changer de bande",
			band: "Bande",
			channel: "Canal",
		},
		vnc_view: {
			heading: "VNC",
			alert_unreachable_title: "VNC inaccessible",
			alert_unreachable_body:
				"Il semble y avoir un problème lors de la tentative d'établissement de la connexion à la visionneuse VNC",
		},
		web_manager_view: {
			alert_unreachable_title: "Gestionnaire Web inaccessible",
			alert_unreachable_body:
				"Il semble y avoir un problème lors de la tentative d’établissement de la connexion à Web Manager",
		},
	},
	settings: {
		heading: "REGLAGES",
		language: "Langue",
		high_bandwidth: "Bande passante élevée",
		transfer_images: "Transférer des images",
		notifications: "Notifications",
		sounds: "Activer le Son",
		cloud_storage: "Stockage en ligne",
		limit_cloud_storage:
			"Limited Storage. Upgrade to Pro/Ultimate for up to 10GB/1000GB storage ",
		auto_sync: "Synchronisation automatique",
		location: "Emplacement",
		reset_app: "Réinitialiser l'application",
		reset_app_body: "Purger tous les paramètres de l'application?",
		metric: "Metric",
		imperial: "Imperial",
		file_logging: "File logging",
		alert_delete_account_title: "Delete Account",
		alert_delete_account_body: "Are you sure you want to delete your account?",
		color_scheme_sky_map: "Sky Map Scheme",
		last_synced: "Last synced",
		sync_all: "Sync All data",
		pro_feature: "Pro feature",
	},
	cloud: {
		heading: "VUE",
		btn_filters: "Filtres",
		btn_gallery: "Galerie",
		btn_offline_viewer: "Visionneuse hors ligne",
		btn_cloud_viewer: "Cloud Viewer",
		no_images_title: "Aucune image trouvée",
		enable_cloud:
			"To start capturing sequence images, please enable EkosLive Cloud Upload in Settings.",
		no_images_body: "Essayez de changer les filtres.",
		no_selected_title: "Aucune image sélectionnée",
		no_selected_body: "Sélectionnez une image de Galley",
		cloud_disabled_title: "Le viewer est désactivé",
		cloud_disabled_body:
			"Accédez aux paramètres et activez Cloud pour pouvoir accéder à cet onglet.",
		pro_plan_body:
			"Le stockage dans le viewer n'est disponible que pour les utilisateurs du plan Pro.",
		reset_ekoslive: "Reset Ekoslive",
		alert_cannot_download_title: "Ne peut pas télécharger",
		alert_cannot_download_body:
			"Une erreur s'est produite lors de la tentative de téléchargement.",
		alert_confirm_delete_title: "Êtes-vous sûr?",
		alert_confirm_delete_body:
			"Souhaitez-vous vraiment supprimer {0}? Une fois supprimé, il ne peut plus être récupéré.",
		filters_drawer: {
			field: "Champ",
			condition: "État",
			value: "Valeur",
			btn_add_filter: "Ajouter un Filtre",
			airmass: "Masse d'air",
			object: "Objet",
			contains: "Contient",
		},
		gallery_drawer: {
			all: "tous",
			h1: "1h",
			h24: "24h",
			d7: "7d",
			m1: "1m",
			y1: "1y",
			images: "Images",
			btn_sort: "Trier",
			btn_delete_all: "Supprimer Tout",
			ascending: "A-Z",
			descending: "Z-A",
			alert_no_images_title: "Aucune image",
			alert_no_images_body:
				"Il n'y a aucune image dans la galerie qui pourrait être supprimée.",
			alert_delete_all_body:
				"Voulez-vous vraiment supprimer {0} image (s)? C'est une opération irréversible.",
			transfer_successful: "Image transfer is successful",
		},
		info_drawer: {
			add_tags_here: "Ajoutez des tags ici",
			captured_on: "Capturé sur",
		},
	},
	about: {
		heading: "À propos",
		bundle: "Paquet",
		copyright_line1:
			"StellarMate est développé par Ikarus Technologies © 2017-2025",
		copyright_line2: "Image d'arrière-plan par l'Observatoire Alamri",
		credits: {
			heading: "Credits",
			title: "Translations",
			name: "Translator name",
		},
	},
	messages: {
		polar_idle:
			"Cette outil propose une méthode simple pour l'alignement polaire des monture équatoriale Allemande. Parquer votre monture vers l'étoile polaire ave le contre-poids vers le bas. Sélectionnez la direction et la vitesse de la monture, puis cliquez sur Démarrer pour commencer le processus. Si vous n'avez pas de vue sur le pôle, utilisez le contrôle de la monture avec Carte Du Ciel ou Ekos pour pointer n'importe où, de préférence près du méridien, choisissez Est ou Ouest selon votre côté, diminuez l'angle de rotation si nécessaire, et cliquez sur Démarrer.",
		alert_directions_not_found:
			"Coordinates not found, Please frame your target or enter coordinates manually.",
		manual_rotator:
			"Rotate camera by the indicated angle and then take an image to update the position angle",
		welcome: "Welcome to StellarMate",
		no_logs: "No {0} logs found",
		mount_set: "Setting home position to current position.",
	},
	ekosLivePro: {
		pushNotification: "Push Notification",
		level: "Notification Level",
		settings: "EkosLive Pro Settings",
	},
	skymap: {
		goto: "Aller à",
	},
	device: {
		mirror: "Mirror External Display",
		monitor_successful: "StellarMate monitor Display changed successfully!",
		monitor_failed:
			"Failed to set StellarMate monitor display. Please try again later!",
		undefined_error: "Error is undefined",
		share: "Share logs",
		logs: "Logs",
		logs_upload: "Logs uploaded successfully to StellarMate support.",
		error_upload: "Error uploading logs",
		reset_network: "Reset network",
		release_notes: "Release Notes",

		vnc: {
			update_password: "Update VNC Password",
			scaling: "Scaling",
		},
	},
	controller: {
		dc_power: "12V DC Power",
		heaters: "Dew Heaters",
		varOutput: "Variable Output",
		reports: "Reports",
		voltage: "Voltage",
		current: "Current",
		power: "Power",
		active_antenna: "Active Antenna",
		passive_antenna: "Passive Antenna",
		power_sleep: "Power is in sleep mode. Tap to wake up.",
		power_off: "Power Off",
		power_off_tooltip:
			"Power Off all ports when equipment profile is disconnected.",
		leds: "LEDs",
		auto_dew: "Auto Dew",
		auto_dew1_threshold: "Dew 1 Thresh.",
		auto_dew2_threshold: "Dew 2 Thresh.",
		stepper: "Stepper",
		buzzer: "Buzzer",
		sensors: "Sensors",
		alert_power_port_description: "Are you sure you want to toggle off {0}?",
	},
	notifications: {
		read_all: "Read all",
		dnd: "Do not disturb",
		ekoslive_successful: "Ekoslive Reset is Successful",
		ekoslive_restart: "Restarting Ekoslive",
		usb_reset: "USB reset is successful",
		feedback: "Feedback",
		stella_feedback_optional: "(Optional) Feel free to add more details.",
		stella_feedback: "Feedback submitted successfully.",
		stella_feedback_placeholder: "Please provide additional feedback",
		stella_prompt_request: "Request for Stella handled successfully",
		stella_xml_failure: "Failed to generate XML",
		stella_history_success: "History deleted successfully.",
		stella_history_failure: "Error deleting history",
		train_refresh: "Trains Refreshed Successfully",
	},
	hardware: {
		serial_devices_detected: "Serial Devices Detected",
		serial_port: "Serial Port",
	},
	tabs: {
		setup: "Setup",
		ekos: "Ekos",
		sky: "Sky",
		targets: "Targets",
		device: "Device",
		stella: "Stella",
	},
	stella: {
		confirm_train_settings:
			"Cannot generate XML without capture settings. Start Ekos profile first to sync default settings.",
		confirm_ekos_running:
			"Please check the Ekos profile. Ekos must be online to continue",
		confirm_ekos_running_question:
			"This functionality requires Ekos Profile to be running. Would you like me to start the default profile?",
		action_in_progress: "Action in progress",
		action_completed: "Action completed successfully",
		confirm: "Confirm Response",
		narrate: "Narrate Response",
		notifications: {
			objects: "Stella has found some interesting objects",
			failed:
				"Stella didn't find anything this time. Could you try widening your search criteria or adjusting the filters?",
			pending: "Processing...",
			XML: {
				pending: "Generating XML...",
				error: "XML generation failed",
				success: "XML generated successfully",
			},
			image: {
				pending: "Processing image...",
				error: "Processing image error",
				success: "Processed image successfully",
			},
		},

		targets: {
			example: "Filter targets with Stella AI. Here are some examples:",
			galaxies: "1. Find all galaxies above 30 degrees at the North",
			nebulae: "2. Find all nebulae that are between 1 and 3 degrees",
			comets:
				"3. Find all comets above 40 degrees and I can observe with my camera",
		},
		view: {
			example: "Filter images with Stella AI. Here are some examples:",
			filter: "1. Filter images with Red filter",
			show_images: "2. Show me images that I took May 25",
			show_messier: "3. Show me M 31 images",
		},
	},
	social: {
		social_profile: "Social Profile",
		captures: "Captures",
		followers: "Followers",
		subscriptions: "Subscriptions",
		following: "Following",
		follow: "Follow",
		unfollow: "Unfollow",
		report_abuse: {
			title: "Report abuse",
			description: "Please specify a reason for this abuse report.",
			reason: {
				spam: "The content is filled with irrelevant or repetitive messages.",
				hate_speech:
					"The content contains racist, sexist, or otherwise discriminatory language.",
				inappropriate_content:
					"The post contains explicit, offensive, or NSFW material.",
				impersonation: "The user is pretending to be someone they are not.",
				violation_of_terms:
					"The content violates the platform's terms of service or community guidelines.",
				privacy_violation:
					"The content includes personal information without consent.",
				wrong_image: "The image is incorrect",
				wrong_metadata: "The metadata is incorrect",
			},
		},
		comment: {
			comments: "Comments",
			no_comment: "There is no Comment.",
		},
		new_post: "New Post",
		public_directory: "Public Directory",
		community: "Community",
		no_observatories: "No public observatories found",
		fine_location: "Fine Location",
		coarse_location: "Coarse Location",
	},
	menu: {
		go_to_profile: "Profile",
		go_to_equipment: "Equipment",
		go_to_moderation: "Moderation",
		manage_subscriptions: "Subscriptions",
		go_to_dashboard: "Dashboard",
	},
	wizard: {
		software_title: "Which imaging software do you use?",
		hardware_title: "What hardware plaforms do you use?",
		imaging_title: "What do you like to image?",
	},
	ekoslive: {
		info: {
			drag: "Drag & drop a file here, or click to select a file",
			selected: "File has been selected",
		},
	},
};

export default fr;
