import React from "react";

const ChatIcon = ({ width = 25, height = 25, color = "#FF6600" }) =>
{
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13 3H11C6.02944 3 2 7.02944 2 12V17C2 19.2091 3.79086 21 6 21H13C17.9706 21 22 16.9706 22 12C22 7.02944 17.9706 3 13 3Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default ChatIcon;
