import { bookingConstants } from "../constants/bookings.constants";

export const bookingAction = {
    setBookingDates
};

function setBookingDates(bookingDates)
{
    return {
        type: bookingConstants.SET_BOOKING_DATES,
        payload: bookingDates,
    }
}