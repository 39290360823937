import React from "react";

const ArrowsIcon = ({ width = 25, height = 25, color = "inherit" }) =>
{
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 14 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			color={color}
		>
			<path
				d="M8.4 3.83876C8.4 3.69476 8.3315 3.55926 8.215 3.47276L4.3345 0.592262C4.1935 0.487762 4.004 0.470762 3.845 0.547762C3.6865 0.625262 3.5865 0.783762 3.5865 0.958262V2.19376H0.4665C0.209 2.19376 0 2.39826 0 2.65226V5.02676C0 5.28026 0.209 5.48526 0.4665 5.48526H3.5865V6.71976C3.5865 6.89426 3.6865 7.05276 3.8455 7.13026C3.9115 7.16226 3.9825 7.17826 4.0535 7.17826C4.1535 7.17826 4.2525 7.14676 4.335 7.08526L8.2155 4.20376C8.3315 4.11676 8.4 3.98226 8.4 3.83876ZM13.5335 6.51526H10.406V5.27976C10.406 5.10526 10.3055 4.94676 10.1465 4.86926C9.989 4.79226 9.799 4.80926 9.657 4.91476L5.7845 7.79576C5.668 7.88276 5.5995 8.01726 5.5995 8.16076C5.5995 8.30476 5.668 8.44026 5.7845 8.52626L9.657 11.4068C9.7395 11.4683 9.839 11.4998 9.939 11.4998C10.01 11.4998 10.081 11.4843 10.147 11.4518C10.3055 11.3743 10.406 11.2153 10.406 11.0413V9.80576H13.5335C13.791 9.80576 14 9.60126 14 9.34726V6.97376C14 6.72026 13.791 6.51526 13.5335 6.51526Z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default ArrowsIcon;
