import React from "react";

const CalendarIcon = ({
	width = 24,
	height = 24,
	color = "currentColor",
	className = "",
}) =>
{
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
			className={className}
		>
			<path d="M8 2v4" />
			<path d="M16 2v4" />
			<path d="M21 14V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h8" />
			<path d="M3 10h18" />
			<path d="m16 20 2 2 4-4" />
		</svg>
	);
};

export default CalendarIcon;
