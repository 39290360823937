export const contextConstants = {
    SET_CONTEXT: 'SET_CONTEXT',

    SHOW_ADD_PROFILE: "SHOW_ADD_PROFILE",
    PROFILE_DATA: "PROFILE_DATA",
    GALLERY_DATA: "GALLERY_DATA",
    SETUP_ROUTE: "SETUP_ROUTE",
    HARDWARE_INFO: "HARDWARE_INFO",
    CLOUD_MAP: "CLOUD_MAP",
    DSLR_INFO: "DSLR_INFO",
    OPTICAL_TRAIN: "OPTICAL_TRAIN",
    SCOPE_DIALOG_TYPE: "SCOPE_DIALOG_TYPE",
    SCOPE_INFO: "SCOPE_INFO",
    TELESCOPE_LENS: "TELESCOPE_LENS",
    EKOS_HAND_CURSOR: "EKOS_HAND_CURSOR",
    EKOS_OPTICAL_TRAIN: "EKOS_OPTICAL_TRAIN",
    MODULE_SETTINGS: "MODULE_SETTINGS",
    SHOW_ALIGN_PAA: "SHOW_ALIGN_PAA",
    FOCUS_GRAPH_DATA: "FOCUS_GRAPH_DATA",
    ALIGN_GRAPH_DATA: "ALIGN_GRAPH_DATA",

    WARNING_CELESTIAL_POLE: "WARNING_CELESTIAL_POLE",
    WARNING_FOV_SIZE: "WARNING_FOV_SIZE",
    ALIGN_RECOMMENDED_SETTINGS: "ALIGN_RECOMMENDED_SETTINGS",
    CHECK_ASTROMETRY_INDEX: "CHECK_ASTROMETRY_INDEX",
    SAVE_JOB_SEQUENCE_NAME: "SAVE_JOB_SEQUENCE_NAME",
    LOGS_TEXT_SIZE: "LOGS_TEXT_SIZE"
};
