import { Box, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import s from "@common/shared/i18n/i18n";
import { useNavigate } from "react-router-dom";
import { Palette } from "@common/shared/common/styles";

const Updates = () =>
{
	const navigate = useNavigate();
	return (
		<Box
			sx={{
				transform: "translateZ(0px)",
				flexGrow: 1,
				position: "absolute",
				bottom: "20px",
				left: "20px",
			}}
		>
			<ListItemButton onClick={() => navigate("/updates")}>
				<ListItemIcon sx={{ color: "white" }}>
					<Box
						sx={{
							borderRadius: "20px",
							backgroundColor: "#FFFFFF33",
							width: "35px",
							height: "35px",
							display: "inline-grid",
							placeItems: "center",
						}}
					>
						<NotificationsActiveIcon
							sx={{ color: Palette.white, fontSize: 16 }}
						/>
					</Box>
				</ListItemIcon>
				<ListItemText style={{ color: "white" }} primary={s.status.updates} />
			</ListItemButton>
		</Box>
	);
};

export default Updates;
