import React from 'react'

const CameraIcon = ({ width = 25, height = 25, color = "#CECECE" }) =>
{
    return (
        <svg width={width} height={height} viewBox="0 0 25 25" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M12.8804 17.0886C14.4802 17.0886 15.777 15.7917 15.777 14.192C15.777 12.5923 14.4802 11.2955 12.8804 11.2955C11.2807 11.2955 9.98389 12.5923 9.98389 14.192C9.98389 15.7917 11.2807 17.0886 12.8804 17.0886Z" fill="#CECECE" />
            <path d="M21.5701 7.15756H18.8565C18.6827 7.15761 18.5133 7.10295 18.3724 7.00135C18.2314 6.89975 18.126 6.75635 18.0711 6.59149L17.8096 5.80612C17.6996 5.47655 17.4887 5.18994 17.2068 4.9869C16.9249 4.78385 16.5862 4.67466 16.2388 4.6748H9.5213C9.17391 4.67489 8.83536 4.78428 8.5536 4.98747C8.27183 5.19066 8.06114 5.47736 7.95137 5.80694L7.68985 6.59149C7.63494 6.75635 7.52953 6.89975 7.38857 7.00135C7.2476 7.10295 7.07824 7.15761 6.90447 7.15756H4.19082C3.75184 7.15756 3.33084 7.33195 3.02043 7.64235C2.71003 7.95276 2.53564 8.37376 2.53564 8.81274V18.7438C2.53564 19.1827 2.71003 19.6037 3.02043 19.9142C3.33084 20.2246 3.75184 20.3989 4.19082 20.3989H21.5701C22.0091 20.3989 22.4301 20.2246 22.7405 19.9142C23.0509 19.6037 23.2253 19.1827 23.2253 18.7438V8.81274C23.2253 8.37376 23.0509 7.95276 22.7405 7.64235C22.4301 7.33195 22.0091 7.15756 21.5701 7.15756ZM12.8805 18.7438C10.3712 18.7438 8.32875 16.7013 8.32875 14.192C8.32875 11.6828 10.3712 9.64032 12.8805 9.64032C15.3897 9.64032 17.4322 11.6828 17.4322 14.192C17.4322 16.7013 15.3897 18.7438 12.8805 18.7438ZM17.846 10.4679C17.6265 10.4679 17.416 10.3807 17.2608 10.2255C17.1056 10.0703 17.0184 9.85981 17.0184 9.64032C17.0184 9.42083 17.1056 9.21033 17.2608 9.05513C17.416 8.89993 17.6265 8.81274 17.846 8.81274C18.0655 8.81274 18.276 8.89993 18.4312 9.05513C18.5864 9.21033 18.6736 9.42083 18.6736 9.64032C18.6736 9.85981 18.5864 10.0703 18.4312 10.2255C18.276 10.3807 18.0655 10.4679 17.846 10.4679Z" />
            <path d="M5.43225 6.32997H3.77707C3.66733 6.32997 3.56208 6.28637 3.48448 6.20877C3.40688 6.13117 3.36328 6.02592 3.36328 5.91617C3.36328 5.80643 3.40688 5.70118 3.48448 5.62358C3.56208 5.54598 3.66733 5.50238 3.77707 5.50238H5.43225C5.54199 5.50238 5.64724 5.54598 5.72484 5.62358C5.80244 5.70118 5.84604 5.80643 5.84604 5.91617C5.84604 6.02592 5.80244 6.13117 5.72484 6.20877C5.64724 6.28637 5.54199 6.32997 5.43225 6.32997Z" />
        </svg>
    )
}

export default CameraIcon