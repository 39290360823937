import { bookingConstants } from "../constants";

const initialState =
{
    bookingDates: []
};

export const bookings = (state = initialState, action) =>
{
    switch (action.type)
    {
        case bookingConstants.SET_BOOKING_DATES:
            {
                return { ...state, bookingDates: action.payload };
            };

        default:
            {
                return state;
            }

    }
};
