const badges = {
	capture_preview: "badges/first_light",
	ten_sequences: "badges/begins",
	mount_goto: "badges/magic_fingers",
	video_recording: "badges/director_cut",
	weather_check: "badges/cloud_magnet",
	live_stacking: "badges/details",
	create_darks: "badges/dark_side",
	create_defects: "badges/cosmic_makeup",
	import_mosaic: "badges/mosaic_weaver",
	capture_master: "badges/sky_master",
	capture_legend: "badges/sky_legend",
	all_messiers: "badges/cosmic_marathon",
	paa_arcsec: "badges/perfectionist",
	guide_rms: "badges/bullseye",
	filtered_image: "badges/wave_splitter",
	gallery_image: "badges/storage",
	explorer: "badges/explorer",
	m1: "messiers/m1",
	m2: "messiers/m2",
	m3: "messiers/m3",
	m4: "messiers/m4",
	m5: "messiers/m5",
	m6: "messiers/m6",
	m7: "messiers/m7",
	m8: "messiers/m8",
	m9: "messiers/m9",
	m10: "messiers/m10",
	m11: "messiers/m11",
	m12: "messiers/m12",
	m13: "messiers/m13",
	m14: "messiers/m14",
	m15: "messiers/m15",
	m16: "messiers/m16",
	m17: "messiers/m17",
	m18: "messiers/m18",
	m19: "messiers/m19",
	m20: "messiers/m20",
	m21: "messiers/m21",
	m22: "messiers/m22",
	m23: "messiers/m23",
	m24: "messiers/m24",
	m25: "messiers/m25",
	m26: "messiers/m26",
	m27: "messiers/m27",
	m28: "messiers/m28",
	m29: "messiers/m29",
	m30: "messiers/m30",
	m31: "messiers/m31",
	m32: "messiers/m32",
	m33: "messiers/m33",
	m34: "messiers/m34",
	m35: "messiers/m35",
	m36: "messiers/m36",
	m37: "messiers/m37",
	m38: "messiers/m38",
	m39: "messiers/m39",
	m40: "messiers/m40",
	m41: "messiers/m41",
	m42: "messiers/m42",
	m43: "messiers/m43",
	m44: "messiers/m44",
	m45: "messiers/m45",
	m46: "messiers/m46",
	m47: "messiers/m47",
	m48: "messiers/m48",
	m49: "messiers/m49",
	m50: "messiers/m50",
	m51: "messiers/m51",
	m52: "messiers/m52",
	m53: "messiers/m53",
	m54: "messiers/m54",
	m55: "messiers/m55",
	m56: "messiers/m56",
	m57: "messiers/m57",
	m58: "messiers/m58",
	m59: "messiers/m59",
	m60: "messiers/m60",
	m61: "messiers/m61",
	m62: "messiers/m62",
	m63: "messiers/m63",
	m64: "messiers/m64",
	m65: "messiers/m65",
	m66: "messiers/m66",
	m67: "messiers/m67",
	m68: "messiers/m68",
	m69: "messiers/m69",
	m70: "messiers/m70",
	m71: "messiers/m71",
	m72: "messiers/m71",
	m73: "messiers/m73",
	m74: "messiers/m74",
	m75: "messiers/m75",
	m76: "messiers/m76",
	m77: "messiers/m77",
	m78: "messiers/m78",
	m79: "messiers/m79",
	m80: "messiers/m80",
	m81: "messiers/m81",
	m82: "messiers/m82",
	m83: "messiers/m83",
	m84: "messiers/m84",
	m85: "messiers/m85",
	m86: "messiers/m86",
	m87: "messiers/m87",
	m88: "messiers/m88",
	m89: "messiers/m89",
	m90: "messiers/m90",
	m91: "messiers/m91",
	m92: "messiers/m92",
	m93: "messiers/m93",
	m94: "messiers/m94",
	m95: "messiers/m95",
	m96: "messiers/m96",
	m97: "messiers/m97",
	m98: "messiers/m98",
	m99: "messiers/m99",
	m100: "messiers/m100",
	m101: "messiers/m101",
	m102: "messiers/m102",
	m103: "messiers/m103",
	m104: "messiers/m104",
	m105: "messiers/m105",
	m106: "messiers/m106",
	m107: "messiers/m107",
	m108: "messiers/m108",
	m109: "messiers/m109",
	m110: "messiers/m110",
};

export default badges;
