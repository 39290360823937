export const ekosConstants = {
	EKOS_CLEAR_NOTIFICATION: "CLEAR_NOTIFICATION",
	EKOS_CAPTURE_PREVIEW: "CAPTURE_PREVIEW",
	REMOVE_DIALOG: "REMOVE_DIALOG",
	LIVESTACK_UPDATE_IMAGE: "LIVESTACK_UPDATE_IMAGE",
	LIVESTACK_CLEAR_DATA: "LIVESTACK_CLEAR_DATA",
	CLEAR_RAW_DATA: "CLEAR_RAW_DATA",
	CLEAR_IMAGE_DATA: "CLEAR_CAPTURE_DATA",
	CLOSE_MANUAL_ROTATOR: "close_manual_rotator",
	SET_IMAGE_LOADING: "SET_IMAGE_LOADING",

	GET_ECHO_DATA: "ECHO_GET_DATA",
	RESET_ECHO_DATA: "RESET_ECHO_DATA",

	SET_SUMMARY_DATA: "SET_SUMMARY_DATA",
	RESET_FOCUS_GRAPH: "RESET_FOCUS_GRAPH",
	RESET_GUIDE_GRAPH: "RESET_GUIDE_GRAPH",
};
