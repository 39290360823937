import { cn } from "@utils/cn";
import React from "react";

const Logout = ({ width = 25, height = 25, color = "inherit", className }) =>
{
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			color={color}
			className={cn(className)}
		>
			<g>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M4.66667 1.5C2.91777 1.5 1.5 2.91777 1.5 4.66667V11.3333C1.5 13.0822 2.91777 14.5 4.66667 14.5H7.33333C9.08224 14.5 10.5 13.0822 10.5 11.3333V11C10.5 10.7239 10.2761 10.5 10 10.5C9.72386 10.5 9.5 10.7239 9.5 11V11.3333C9.5 12.53 8.52995 13.5 7.33333 13.5H4.66667C3.47005 13.5 2.5 12.53 2.5 11.3333V4.66667C2.5 3.47005 3.47005 2.5 4.66667 2.5H7.33333C8.52995 2.5 9.5 3.47005 9.5 4.66667V5C9.5 5.27614 9.72386 5.5 10 5.5C10.2761 5.5 10.5 5.27614 10.5 5V4.66667C10.5 2.91777 9.08223 1.5 7.33333 1.5H4.66667ZM7.64645 5.64645C7.84171 5.45118 8.15829 5.45118 8.35355 5.64645C8.54882 5.84171 8.54882 6.15829 8.35355 6.35355L7.20711 7.5H14C14.2761 7.5 14.5 7.72386 14.5 8C14.5 8.27614 14.2761 8.5 14 8.5H7.20711L8.35355 9.64645C8.54882 9.84171 8.54882 10.1583 8.35355 10.3536C8.15829 10.5488 7.84171 10.5488 7.64645 10.3536L5.64645 8.35355C5.45118 8.15829 5.45118 7.84171 5.64645 7.64645L7.64645 5.64645Z"
					fill="currentColor"
				/>
			</g>
		</svg>
	);
};

export default Logout;
