import React from "react";

const ObservatoryIcon = ({
	width = 24,
	height = 24,
	color = "currentColor",
}) =>
{
	return (
		<svg
			fill={color}
			height={height}
			width={width}
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox="0 0 512 512"
			xmlSpace="preserve"
		>
			<g>
				<g>
					<path
						d="M99.951,498.699c-0.134-0.712-0.356-1.414-0.634-2.093c-0.278-0.669-0.623-1.313-1.024-1.914
                    c-0.401-0.613-0.868-1.181-1.38-1.693c-0.512-0.512-1.08-0.979-1.692-1.38c-0.601-0.4-1.247-0.746-1.926-1.024
                    c-0.668-0.278-1.369-0.501-2.081-0.633c-1.436-0.29-2.905-0.29-4.341,0c-0.712,0.132-1.414,0.355-2.093,0.633
                    c-0.668,0.278-1.313,0.624-1.914,1.024c-0.612,0.401-1.18,0.868-1.692,1.38c-0.512,0.512-0.979,1.08-1.38,1.693
                    c-0.401,0.601-0.746,1.245-1.024,1.914c-0.278,0.679-0.501,1.38-0.634,2.093c-0.145,0.712-0.223,1.447-0.223,2.17
                    c0,0.722,0.078,1.458,0.223,2.17c0.134,0.712,0.356,1.414,0.634,2.08c0.278,0.679,0.623,1.325,1.024,1.926
                    c0.401,0.612,0.868,1.18,1.38,1.692c0.512,0.513,1.08,0.981,1.692,1.38c0.601,0.401,1.247,0.746,1.914,1.024
                    c0.679,0.278,1.38,0.501,2.093,0.634c0.712,0.145,1.447,0.223,2.17,0.223c0.723,0,1.458-0.078,2.17-0.223
                    c0.712-0.134,1.414-0.356,2.081-0.634c0.679-0.278,1.325-0.623,1.926-1.024c0.612-0.4,1.18-0.867,1.692-1.38
                    c0.512-0.512,0.979-1.08,1.38-1.692c0.401-0.601,0.746-1.247,1.024-1.926c0.278-0.668,0.501-1.368,0.634-2.08
                    c0.145-0.713,0.223-1.448,0.223-2.17C100.174,500.146,100.096,499.41,99.951,498.699z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path
						d="M500.87,445.217h-33.391V322.783V256c0-33.233-7.716-64.696-21.434-92.704c-0.269-0.715-0.607-1.392-1.01-2.029
                    c-6.959-13.828-15.388-26.794-25.086-38.688l7.618-7.618l7.87,7.869c2.174,2.173,5.022,3.26,7.87,3.26s5.698-1.087,7.87-3.26
                    l31.481-31.481c4.346-4.346,4.346-11.394,0-15.742l-62.963-62.963c-4.346-4.345-11.394-4.345-15.742,0l-31.481,31.481
                    c-4.346,4.346-4.346,11.394,0,15.742l7.87,7.87l-10.264,10.264c-30.004-19.298-65.181-31.22-102.949-33.188V11.13
                    C267.13,4.984,262.147,0,256,0C187.62,0,123.333,26.628,74.981,74.98C26.628,123.333,0,187.619,0,256
                    c0,6.146,4.983,11.13,11.13,11.13h33.391v55.652v122.435H11.13c-6.147,0-11.13,4.984-11.13,11.13v44.522
                    C0,507.016,4.983,512,11.13,512h44.522c6.147,0,11.13-4.984,11.13-11.13s-4.983-11.13-11.13-11.13H22.261v-22.261h33.391h155.826
                    h89.043h155.826h33.391v22.261H122.435c-6.147,0-11.13,4.984-11.13,11.13s4.983,11.13,11.13,11.13H500.87
                    c6.147,0,11.13-4.984,11.13-11.13v-44.522C512,450.202,507.017,445.217,500.87,445.217z M428.537,178.342
                    c9.265,20.503,14.968,42.943,16.343,66.528h-88.842c-0.546-23.093-2.793-45.508-6.603-66.528H428.537z M411.826,36.256
                    l47.222,47.222l-15.741,15.741L425.599,81.51l-21.643-21.644c-0.003-0.003-0.007-0.006-0.01-0.01l-7.86-7.86L411.826,36.256z
                     M396.085,83.478l15.741,15.742l-6.773,6.773c-5.276-5.241-10.828-10.208-16.617-14.865L396.085,83.478z M416.612,156.081h-71.927
                    c-4.141-16.74-9.347-32.311-15.551-46.269c-6.261-14.087-13.338-26.043-21.044-35.735
                    C353.574,87.119,392.089,116.803,416.612,156.081z M67.118,244.87c1.376-23.583,7.079-46.024,16.344-66.528h79.102
                    c-3.809,21.019-6.055,43.434-6.603,66.528H67.118z M155.826,267.13v44.522H66.783V267.13H155.826z M95.388,156.081
                    c24.524-39.278,63.038-68.962,108.523-82.005c-7.708,9.692-14.785,21.649-21.045,35.735c-6.204,13.959-11.411,29.53-15.551,46.269
                    H95.388z M185.19,178.342h141.62c4.016,20.823,6.387,43.273,6.961,66.528H178.229C178.804,221.616,181.173,199.166,185.19,178.342
                    z M178.087,267.13h155.826v44.522H178.087V267.13z M308.792,118.853c5.03,11.316,9.356,23.817,12.934,37.228h-131.45
                    c3.577-13.411,7.904-25.912,12.934-37.228c14.707-33.091,33.949-52.07,52.792-52.07S294.085,85.762,308.792,118.853z
                     M61.598,172.786c-0.087,0.201-0.178,0.4-0.264,0.601c-0.437,1.027-0.858,2.064-1.28,3.1c-0.207,0.508-0.42,1.013-0.623,1.523
                    c-0.376,0.944-0.737,1.896-1.099,2.846c-0.233,0.609-0.47,1.217-0.696,1.829c-0.337,0.907-0.66,1.822-0.985,2.736
                    c-0.236,0.663-0.474,1.326-0.703,1.992c-0.305,0.886-0.598,1.776-0.89,2.668c-0.232,0.703-0.464,1.408-0.688,2.115
                    c-0.277,0.873-0.544,1.749-0.809,2.625c-0.223,0.736-0.444,1.471-0.659,2.209c-0.25,0.862-0.494,1.727-0.735,2.593
                    c-0.211,0.762-0.419,1.526-0.621,2.292c-0.226,0.855-0.447,1.711-0.663,2.569c-0.198,0.788-0.391,1.578-0.58,2.37
                    c-0.203,0.846-0.401,1.692-0.593,2.542c-0.184,0.814-0.36,1.631-0.534,2.449c-0.178,0.836-0.356,1.672-0.524,2.512
                    c-0.169,0.844-0.328,1.691-0.487,2.538c-0.155,0.821-0.311,1.643-0.455,2.468c-0.155,0.882-0.297,1.765-0.441,2.65
                    c-0.129,0.799-0.263,1.596-0.384,2.398c-0.14,0.935-0.266,1.875-0.395,2.815c-0.104,0.759-0.214,1.516-0.309,2.277
                    c-0.13,1.038-0.243,2.081-0.358,3.124c-0.073,0.669-0.157,1.335-0.224,2.005c-0.141,1.405-0.263,2.815-0.376,4.227
                    c-0.026,0.314-0.059,0.627-0.082,0.941c-0.128,1.686-0.237,3.378-0.326,5.074h-22.29C28.174,124.817,124.817,28.174,244.87,22.522
                    v22.289c-1.696,0.089-3.387,0.197-5.072,0.326c-0.333,0.024-0.663,0.06-0.997,0.088c-1.393,0.112-2.786,0.232-4.172,0.371
                    c-0.678,0.069-1.351,0.153-2.028,0.227c-1.035,0.115-2.07,0.226-3.101,0.355c-0.769,0.097-1.533,0.208-2.3,0.313
                    c-0.93,0.127-1.862,0.253-2.789,0.392c-0.808,0.121-1.611,0.256-2.415,0.386c-0.879,0.142-1.76,0.284-2.635,0.439
                    c-0.829,0.146-1.655,0.302-2.482,0.457c-0.841,0.158-1.682,0.316-2.52,0.484c-0.846,0.17-1.69,0.348-2.532,0.529
                    c-0.809,0.172-1.618,0.347-2.424,0.529c-0.857,0.194-1.712,0.394-2.566,0.599c-0.784,0.187-1.566,0.377-2.346,0.574
                    c-0.866,0.217-1.73,0.441-2.591,0.669c-0.756,0.2-1.509,0.405-2.262,0.613c-0.877,0.243-1.752,0.489-2.623,0.742
                    c-0.729,0.211-1.456,0.431-2.182,0.65c-0.885,0.268-1.769,0.538-2.648,0.817c-0.699,0.221-1.394,0.451-2.089,0.68
                    c-0.9,0.296-1.8,0.592-2.695,0.9c-0.654,0.225-1.303,0.459-1.955,0.69c-0.927,0.329-1.854,0.658-2.776,0.998
                    c-0.593,0.22-1.181,0.45-1.772,0.674c-0.968,0.37-1.939,0.737-2.901,1.12c-0.487,0.195-0.971,0.398-1.457,0.597
                    c-1.056,0.43-2.114,0.858-3.161,1.305c-0.173,0.073-0.344,0.152-0.516,0.226c-4.138,1.776-8.209,3.681-12.207,5.709
                    c-0.134,0.068-0.268,0.132-0.402,0.2c-1.11,0.565-2.211,1.15-3.309,1.734c-0.352,0.187-0.706,0.37-1.056,0.558
                    c-1.021,0.551-2.032,1.116-3.042,1.684c-0.42,0.236-0.843,0.467-1.26,0.706c-0.973,0.555-1.937,1.122-2.901,1.692
                    c-0.445,0.264-0.893,0.524-1.336,0.79c-0.942,0.567-1.877,1.144-2.809,1.725c-0.454,0.283-0.909,0.564-1.361,0.851
                    c-0.921,0.582-1.834,1.175-2.745,1.772c-0.451,0.296-0.902,0.591-1.35,0.889c-0.908,0.604-1.811,1.218-2.709,1.835
                    c-0.439,0.302-0.876,0.604-1.312,0.91c-0.899,0.629-1.793,1.264-2.681,1.907c-0.423,0.306-0.844,0.614-1.264,0.923
                    c-0.895,0.657-1.785,1.319-2.67,1.99c-0.396,0.301-0.789,0.604-1.183,0.907c-0.899,0.692-1.794,1.388-2.682,2.094
                    c-0.361,0.287-0.718,0.579-1.077,0.868c-0.913,0.736-1.822,1.475-2.723,2.225c-0.304,0.253-0.602,0.51-0.905,0.766
                    c-0.946,0.797-1.889,1.598-2.82,2.412c-0.209,0.184-0.414,0.37-0.623,0.553c-6.79,5.977-13.194,12.38-19.171,19.171
                    c-0.185,0.209-0.373,0.417-0.557,0.627c-0.81,0.927-1.607,1.867-2.401,2.807c-0.259,0.307-0.521,0.612-0.778,0.92
                    c-0.747,0.896-1.483,1.802-2.215,2.71c-0.293,0.363-0.587,0.724-0.877,1.089c-0.702,0.882-1.394,1.773-2.081,2.667
                    c-0.308,0.4-0.617,0.8-0.923,1.202c-0.667,0.88-1.326,1.765-1.979,2.657c-0.312,0.424-0.623,0.849-0.932,1.277
                    c-0.639,0.884-1.271,1.773-1.897,2.668c-0.309,0.441-0.616,0.885-0.922,1.329c-0.615,0.894-1.225,1.792-1.828,2.696
                    c-0.301,0.452-0.599,0.906-0.896,1.36c-0.594,0.907-1.184,1.819-1.765,2.736c-0.289,0.457-0.574,0.918-0.86,1.378
                    c-0.577,0.926-1.151,1.854-1.713,2.79c-0.272,0.452-0.538,0.907-0.806,1.361c-0.564,0.955-1.128,1.911-1.677,2.875
                    c-0.245,0.429-0.482,0.862-0.724,1.292c-0.561,1-1.121,2-1.666,3.01c-0.198,0.368-0.39,0.74-0.586,1.109
                    c-0.575,1.082-1.151,2.165-1.707,3.258c-0.081,0.16-0.159,0.323-0.24,0.482c-0.09,0.178-0.177,0.358-0.267,0.536
                    c-0.403,0.635-0.741,1.314-1.01,2.029C64.43,166.419,62.975,169.582,61.598,172.786z M289.391,445.217h-66.783v-66.783h66.783
                    V445.217z M445.217,445.217H311.652v-77.913c0-6.146-4.983-11.13-11.13-11.13h-89.043c-6.147,0-11.13,4.984-11.13,11.13v77.913
                    H66.783V333.913h378.435V445.217z M445.217,311.652h-89.044V267.13h89.044V311.652z"
					/>
				</g>
			</g>
		</svg>
	);
};

export default ObservatoryIcon;
