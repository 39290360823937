export const optionsConstants = {
	DRAWER: "drawerOpen",
	CONNECTIONS: "connectionsOpen",
	SETTINGS: "settingsOpen",
	ekosLiveHighBandwidth: "ekosLiveHighBandwidth",
	LANGUAGE: "language",
	ekosLiveImageTransfer: "ekosLiveImageTransfer",
	ekosLiveNotifications: "ekosLiveNotifications",
	ekosLiveSound: "ekosLiveSound",
	ekosLiveCloud: "ekosLiveCloud",
	USER_THEME: "userTheme",
	TOUR_GUIDE: "tourguide",
	captureTrainID: "captureTrainID",

	TIME_SYNC: "timeSync",
	LOCATION_SYNC: "locationSync",
	UNIT_SYSTEM: "unitSystem",
	CURRENT_THEME: "currentTheme",
	FILE_LOGGING: "fileLogging",
	SET_EKOSLIVE_PRO_SETTINGS: "set_ekoslive_pro_settings",
	CATEGORY_GENERAL: "generalCategory",
	CATEGORY_INDI: "indiCategory",
	CATEGORY_FOCUS: "focusCategory",
	CATEGORY_ALIGN: "alignCategory",
	CATEGORY_GUIDE: "guideCategory",
	CATEGORY_CAPTURE: "captureCategory",
	CATEGORY_MOUNT: "mountCategory",
	CATEGORY_OBSERVATORY: "observatoryCategory",
	CATEGORY_SCHEDULER: "schedulerCategory",
	PUSH_NOTIFICATION: "pushNotification",

	DEBUG_LEVEL: "isDebug",
	ALERT_LEVEL: "isAlert",
	INFO_LEVEL: "isInfo",
	WARN_LEVEL: "isWarn",

	COLOR_SCHEME_SKY_MAP: "colorSchemeSkyMap",
};
