export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    USER_LOGIN_REQUESTED: "USER_LOGIN_REQUESTED",
    USER_LOGIN_SUCCEEDED: "USER_LOGIN_SUCCEEDED",
    USER_LOGIN_FAILED: "USER_LOGIN_FAILED",

    REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USER_REGISTER_FAILURE',
    USER_REGISTER_REQUESTED: "USER_REGISTER_REQUESTED",
    USER_REGISTER_SUCCEEDED: "USER_REGISTER_SUCCEEDED",
    USER_REGISTER_FAILED: "USER_REGISTER_FAILED",

    USER_REGISTER_INFO_REQUESTED: "USER_REGISTER_INFO_REQUESTED",
    USER_REGISTER_INFO_SUCCEEDED: "USER_REGISTER_INFO_SUCCEEDED",
    USER_REGISTER_INFO_FAILED: "USER_REGISTER_INFO_FAILED",

    USER_REGISTER_QR_REQUESTED: "USER_REGISTER_QR_REQUESTED",
    USER_REGISTER_QR_SUCCEEDED: "USER_REGISTER_QR_SUCCEEDED",
    USER_REGISTER_QR_FAILED: "USER_REGISTER_QR_FAILED",

    LOGOUT: 'USERS_LOGOUT',
    USER_LOGOUT_REQUESTED: "USER_LOGOUT_REQUESTED",
    USER_LOGGED_OUT: "USER_LOGGED_OUT",
};