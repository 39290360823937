export const messageConstants = {
    SET_MESSAGES: "SET_MESSAGES",
    ADD_MESSAGE: "ADD_MESSAGE",
    SET_CONVERSATIONS: "SET_CONVERSATIONS",
    UPDATE_CONVERSATION: "UPDATE_CONVERSATION",
    SET_UNREAD_COUNT: "SET_UNREAD_COUNT",
    SET_ACTIVE_CONVERSATION: "SET_ACTIVE_CONVERSATION",
    UPDATE_MESSAGE_STATUS: "UPDATE_MESSAGE_STATUS",
    CLEAR_MESSAGES: "CLEAR_MESSAGES",
    NEW_WEBSOCKET_MESSAGE: "NEW_WEBSOCKET_MESSAGE"
};
