import React from "react";
import { APIProvider } from "@vis.gl/react-google-maps";

const MapsApiProvider = ({ children }) =>
{
	return (
		<APIProvider
			apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
			libraries={["geometry"]}
		>
			{children}
		</APIProvider>
	);
};

export default MapsApiProvider;
