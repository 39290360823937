import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import s from "@common/shared/i18n/i18n";

const FollowersButtons = ({ profile }) =>
{
	const { pathname } = useLocation();
	const { nickname } = useParams();
	const { palette } = useTheme();

	return (
		<>
			<Link
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					width: "100%",
				}}
				to={`/community/${nickname ?? "profile"}/followers`}
			>
				<Typography
					sx={{
						color: pathname.includes("followers")
							? palette.primary.main
							: undefined,
					}}
					variant="body1"
					fontWeight={500}
					component="span"
				>
					{s.social.followers}
				</Typography>
				<Typography
					sx={{
						color: pathname.includes("followers")
							? palette.primary.main
							: undefined,
					}}
					variant="body1"
					fontWeight={500}
					component="span"
				>
					{profile.followers?.length ?? 0}
				</Typography>
			</Link>
			<Link
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					width: "100%",
				}}
				to={`/community/${nickname ?? "profile"}/following`}
			>
				<Typography
					sx={{
						color: pathname.includes("following")
							? palette.primary.main
							: undefined,
					}}
					variant="body1"
					fontWeight={500}
					component="span"
				>
					{s.social.following}
				</Typography>
				<Typography
					sx={{
						color: pathname.includes("following")
							? palette.primary.main
							: undefined,
					}}
					variant="body1"
					fontWeight={500}
					component="span"
				>
					{profile.following?.length ?? 0}
				</Typography>
			</Link>
		</>
	);
};

export default FollowersButtons;
