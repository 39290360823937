export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
export const CLEAR_SNACKBAR = 'CLEAR_SNACKBAR';
export const SNACKBAR_VARIANTS = {
  DEFAULT: 0,
  SUCCESS: 1,
  ERROR: 2,
  INFO: 3
};
