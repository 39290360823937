import React from 'react'

const TelescopeIcon = ({ width = 25, height = 25, color = "#CECECE" }) =>
{
    return (
        <svg width={width} height={height} viewBox="0 0 25 25" fill={color} xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1292_6192)">
                <path d="M16.3973 2.57021C16.4309 2.44541 16.5077 2.33982 16.6133 2.27261L18.5237 1.08703C18.7492 0.947806 19.0469 1.01982 19.1861 1.24541L23.3909 8.04703C23.458 8.15742 23.4773 8.28703 23.4485 8.41181C23.4197 8.53181 23.3429 8.64223 23.2325 8.70943L21.3221 9.89021C21.2453 9.93823 21.1589 9.96222 21.0725 9.96222C20.9093 9.96222 20.7557 9.88061 20.6645 9.73181L16.4597 2.93021C16.3925 2.82463 16.3685 2.69021 16.3973 2.57021ZM12.4949 9.8806C11.6453 9.8806 10.8725 10.2118 10.3061 10.759L9.09646 8.8006C8.95727 8.57502 9.02448 8.27742 9.25006 8.13823L15.9797 3.98142L19.5077 9.68862L15.5381 12.1462C15.1445 10.8358 13.9301 9.8806 12.4949 9.8806ZM3.90766 13.255L8.83727 10.207L9.68207 11.575C9.44688 12.0166 9.31246 12.5254 9.31246 13.063C9.31246 13.4182 9.37006 13.759 9.48045 14.0758L5.81328 16.3414C5.73646 16.3894 5.65006 16.4134 5.56367 16.4134C5.40045 16.4134 5.24688 16.3366 5.15568 16.1878L3.75406 13.9126C3.61487 13.6918 3.68207 13.3942 3.90766 13.255ZM1.85805 15.0502L2.92366 14.3926C2.92847 14.4022 2.93805 14.4166 2.94285 14.4262L4.33967 16.6918C4.34447 16.7014 4.34925 16.7062 4.35886 16.7158L3.29327 17.3734C3.21646 17.4214 3.13006 17.4454 3.04366 17.4454C2.88045 17.4454 2.72688 17.3638 2.63567 17.2198L1.70447 15.7126C1.56526 15.487 1.63246 15.1894 1.85805 15.0502Z" />
                <path d="M12.0123 19.2723V15.2244C11.0197 15.0033 10.2727 14.1208 10.2727 13.063C10.2727 11.8427 11.2672 10.8406 12.4951 10.8406C13.7223 10.8406 14.7127 11.8571 14.7127 13.063C14.7127 14.1229 13.9667 15.0072 12.9723 15.2261V19.2723L16.3538 23.2675C16.5249 23.4697 16.4996 23.7725 16.2976 23.9439C16.0949 24.1151 15.7923 24.0901 15.6207 23.8876L12.9723 20.7584V23.5775C12.9723 23.8426 12.7576 24.0575 12.4923 24.0575C12.2269 24.0575 12.0123 23.8426 12.0123 23.5775V20.7585L9.36428 23.8876C9.19319 24.0896 8.89085 24.1159 8.68741 23.9439C8.48538 23.7728 8.46007 23.4697 8.63116 23.2675L12.0123 19.2723Z" />
            </g>
            <defs>
                <clipPath id="clip0_1292_6192">
                    <rect width="24" height="24" fill="white" transform="translate(0.546875 0.536865)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default TelescopeIcon