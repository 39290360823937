import React from "react";

const DomeDeviceIcon = ({
	width = 25,
	height = 25,
	color = "#CECECE",
	className,
}) =>
{
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			color={color}
			className={className}
		>
			<g opacity="0.8">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M11.666 3.3346C11.666 2.41413 12.4122 1.66793 13.3326 1.66793H16.666C17.5864 1.66793 18.3326 2.41413 18.3326 3.3346V8.3346C18.3326 9.25507 17.5864 10.0013 16.666 10.0013H13.3326C12.4122 10.0013 11.666 9.25507 11.666 8.3346V3.3346ZM3.33268 1.66797C2.41221 1.66797 1.66602 2.41416 1.66602 3.33464V5.0013C1.66602 5.92178 2.41221 6.66797 3.33268 6.66797H6.66602C7.58649 6.66797 8.33268 5.92178 8.33268 5.0013V3.33464C8.33268 2.41416 7.58649 1.66797 6.66602 1.66797H3.33268ZM3.33264 10.0013C2.41216 10.0013 1.66597 10.7475 1.66597 11.6679V16.6679C1.66597 17.5884 2.41216 18.3346 3.33264 18.3346H6.66597C7.58644 18.3346 8.33263 17.5884 8.33263 16.6679V11.6679C8.33263 10.7475 7.58644 10.0013 6.66597 10.0013H3.33264ZM13.3326 13.3346C12.4122 13.3346 11.666 14.0808 11.666 15.0013V16.6679C11.666 17.5884 12.4122 18.3346 13.3326 18.3346H16.666C17.5864 18.3346 18.3326 17.5884 18.3326 16.6679V15.0013C18.3326 14.0808 17.5864 13.3346 16.666 13.3346H13.3326Z"
					fill="currentColor"
				/>
			</g>
		</svg>
	);
};

export default DomeDeviceIcon;
