import { isEmpty } from "lodash";
import commands from "../../commands";
import { userConstants, deviceConstants } from "../constants";

/**
 * Has the critical information related to the StellarMate Device and Network
 * 
 **********Sample in JSON form**********
 * {
  "kstarsStatus": 1,
  "notificationToken": "",
  "serverMessage": "",
  "_persist": {
    "rehydrated": true,
    "version": -1
  },
  "isRegistering": false,
  "isScanning": false,
  "isLogging": false,
  "selectedDevice": "stellarmate",
  "regInfo": [
    {
      "password": "",
      "serial": "",
      "license": "",
      "username": "",
      "success": true
    }
  ],
  "scannerMessage": "",
  "newDevicesDetected": 0,
  "scannerProgress": 0,
  "ekosliveStatus": 1,
  "devices": [
    {
      "minimumOSRequired": false,
      "ip": "10.0.2.2",
      "logged": true,
      "default": false,
      "registered": true,
      "user": {
        "remoteToken": "",
        "type": 2,
        "username": "",
        "token": "",
        "serial": "",
        "email": "",
        "license": "",
        "from_date": "",
        "password": "",
        "message": "",
        "to_date": "",
        "plan_id": "1",
        "success": true
      },
      "manual": false,
      "reachable": true,
      "connecting": false,
      "updateAvailable": false,
      "connected": true,
      "hostname": "stellarmate"
    }
  ]
}
 */
const initialState =
{
    /**
     * SM Device, it is an array list. If more SM devices are on the same network. That can be detected as well
     * Has the information:
     * arch: Type of architecture i.e x86_64
     * connected: true; if connected to SM, it will return true
     * connecting: false; Status of whether connecting to SM or not
     * hostname: stellarmate; Device can be accessible using hostname i.e stellarmate.local
     * ip: IP address of the SM device
     * logged: If user is logged in, returns true
     * model: SM-110, Different models of SM devices are there i.e SM X, SM Pro. 
     * reachable: If the SM device is reachable, returns true
     * updateAvailable: If new update is available, returns true
     * user: User's information i.e email, plan_id, remote token, serial etc..
     * version: Current SM OS version, 1.8.0
     */
    devices: [],

    // User details i.e username, license, serial etc
    regInfo: [],

    // scannerProgress: When user press scan, it starts scanning SM devices within the network, i.e progress 50% 
    scannerProgress: 0,
    // If there are any new devices detected
    newDevicesDetected: 0,
    // Selected Device is the name of the device, i.e smx
    selectedDevice: "",
    // Whether scanning is in progress
    isScanning: false,
    // Whether User is getting registered
    isRegistering: false,
    // If it starts logging in, return true
    isLogging: false,

    // When there are errors in logging/connecting/registering, we display the Server message
    serverMessage: "",
    // scannerMessage: If there is any error while scanning, display a message
    scannerMessage: "",

    // Status for Kstars
    kstarsStatus: -1,
    // Status for EKoslive 
    ekosliveStatus: -1,
    // This is a token for notifications generated by Firebase
    notificationToken: ""
};

export function devices(state = initialState, action)
{
    switch (action.type)
    {
        case deviceConstants.KSTARS_STATUS:
            {
                return { ...state, kstarsStatus: action.payload };
            }

        case deviceConstants.CLEAR_SERVER_MESSAGE:
            {
                return { ...state, serverMessage: "" };
            }

        case deviceConstants.LOGOUT_USER_ACCOUNT:
            {
                return initialState;
            }

        case deviceConstants.EKOSLIVE_STATUS:
            {
                return { ...state, ekosliveStatus: action.payload };
            }

        case deviceConstants.DEVICE_SCAN_PROGRESS:
            {
                return { ...state, scannerProgress: action.payload };
            }

        case deviceConstants.DEVICE_SCAN_FAILED:
            {
                return { ...state, scannerProgress: 0, isScanning: false, scannerMessage: action.payload };
            }

        case deviceConstants.DEVICE_SCAN_TOGGLED:
            {
                return { ...state, isScanning: action.payload, scannerProgress: 0, newDevicesDetected: 0 };
            }

        case deviceConstants.DEVICE_ADD:
            {
                let newDevice = action.payload;
                let oldDevice = state.devices.find(dev => dev.ip === newDevice.ip || dev.hostname === newDevice.hostname);
                // If device exists, replace it
                if (isEmpty(oldDevice) === false)
                {
                    newDevice = {
                        ...oldDevice,
                        ip: newDevice.ip,
                        hostname: newDevice.hostname,
                        minimumOSRequired: false,
                        updateAvailable: false
                    };
                    return {
                        ...state,
                        devices: state.devices.map(device => (device.ip === oldDevice.ip ? newDevice : device)),
                        newDevicesDetected: state.newDevicesDetected + 1
                    };
                }
                // If device does not exist, simply add it
                else
                {
                    return {
                        ...state,
                        newDevicesDetected: state.newDevicesDetected + 1,
                        devices: [...state.devices, newDevice]
                    };
                }
            }

        case deviceConstants.DEVICE_CLEAR_ALL:
            return initialState;

        case deviceConstants.DEVICE_REMOVE:
            {
                return {
                    ...state,
                    selectedDevice: "",
                    devices: state.devices.filter(item => item.ip !== action.payload.ip)
                };
            }

        case deviceConstants.DEVICE_UPDATE:
            {
                const updatedDevice = action.payload;
                return {
                    ...state,
                    devices: state.devices.map(device => (device.ip === updatedDevice.ip ? { ...device, ...updatedDevice } : device))
                };
            }

        case deviceConstants.DEVICE_UPDATE_IP:
            {
                return {
                    ...state,
                    devices: state.devices.map(device =>
                        device.hostname === action.payload.hostname ? { ...device, ip: action.payload.ip } : device
                    )
                };
            }

        case deviceConstants.DEVICE_SELECT:
            {
                return {
                    ...state,
                    selectedDevice: action.payload,
                    scannerProgress: 0,
                };
            }

        // LOGIN ACTIONS
        case userConstants.USER_LOGIN_REQUESTED:
            {
                return { ...state, isLogging: true, isRegistering: false };
            }

        case userConstants.USER_LOGIN_FAILED:
            {
                return {
                    ...state,
                    serverMessage: action.payload,
                    isLogging: false,
                    devices: state.devices.map(device => (device.hostname === state.selectedDevice ? {
                        ...device,
                        logged: false
                    } : device))
                };
            }

        case userConstants.USER_LOGIN_SUCCEEDED:
            {
                return {
                    ...state,
                    isLogging: false,
                    isRegistering: false,
                    serverMessage: "",
                    devices: state.devices.map(device => (device.hostname === state.selectedDevice ? {
                        ...device,
                        registered: true,
                        logged: true,
                        user: { ...device.user, ...action.payload }
                    } : device))
                };
            }

        // Registration in progress
        case userConstants.USER_REGISTER_REQUESTED:
            {
                return { ...state, isRegistering: true, isLogging: false };
            }

        // Registration failed
        case userConstants.USER_REGISTER_FAILED:
            {
                return { ...state, serverMessage: action.payload, isRegistering: false, ekosliveStatus: 0 };
            }

        // Registration successful
        case userConstants.USER_REGISTER_SUCCEEDED:
            {
                return {
                    ...state,
                    isRegistering: false,
                    isLogging: false,
                    serverMessage: "",
                    devices: state.devices.map(device => (device.hostname === state.selectedDevice ? {
                        ...device,
                        registered: true,
                        user: { ...state.selectedDevice.user, ...action.payload }
                    } : device))
                };
            }

        case userConstants.USER_REGISTER_QR_REQUESTED:
            {
                return {
                    ...state,
                    isRegistering: true
                };
            }

        case userConstants.USER_REGISTER_QR_SUCCEEDED:
            {
                return {
                    ...state,
                    isRegistering: false,
                    serverMessage: "",
                    regInfo: [...state.regInfo, action.payload]
                };
            }

        case userConstants.USER_REGISTER_QR_FAILED:
            {
                return { ...state, serverMessage: action.payload, isRegistering: false };

            }

        // Registration Info in progress
        case userConstants.USER_REGISTER_INFO_REQUESTED:
            {
                return { ...state, isLogging: true };
            }

        // Registration Info failed
        case userConstants.USER_REGISTER_INFO_FAILED:
            {
                return { ...state, serverMessage: action.payload, isLogging: false };
            }

        // Registration Info successful
        case userConstants.USER_REGISTER_INFO_SUCCEEDED:
            {
                return {
                    ...state,
                    isLogging: false,
                    serverMessage: "",
                    regInfo: [...state.regInfo, action.payload]
                };
            }

        // FIXME clear the password
        case userConstants.USER_LOGOUT_REQUESTED:
            {
                return {
                    ...state,
                    isScanning: false,
                    isRegistering: false,
                    isLogging: false,
                    serverMessage: "",
                    selectedDevice: "",
                    devices: state.devices.map(device => (device.hostname === state.selectedDevice ? {
                        ...device,
                        logged: false,
                        connected: false,
                        connecting: false,
                        user: { ...state.selectedDevice.user, password: "", token: "" }
                    } : device))
                };
            }

        case deviceConstants.MIN_OS_UPDATE_REQUIRED:
            {
                return {
                    ...state,
                    serverMessage: "",
                    devices: state.devices.map(device => (device.hostname === state.selectedDevice ? {
                        ...device,
                        minimumOSRequired: action.payload,
                    } : device))
                };
            }
        case deviceConstants.DEVICE_UPDATE_AVAILABLE:
            {
                return {
                    ...state,
                    devices: state.devices.map(device => (device.hostname === state.selectedDevice ? {
                        ...device,
                        updateAvailable: action.payload,
                    } : device))
                };
            }

        case commands.SAVE_NOTIFICATION_TOKEN:
            {
                return { ...state, notificationToken: action.payload }
            }
        default:
            {
                return state;
            }
    }
}