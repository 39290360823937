import React, { useEffect } from "react";
import UserInfo from "./UserInfo";
import SidebarNavigation from "./SidebarNavigation";
import { useProfileContext } from "@client/Pages/Community/Profile/ProfileContext";
import FollowButton from "./FollowButton";
import SidePanelContainer from "./SidePanelContainer";
import useActiveUser from "@hooks/useActiveUser";
import axios from "axios";
import { captureException } from "@sentry/browser";

const SidePanel = () =>
{
	const { profile, setProfile } = useProfileContext();
	const user = useActiveUser();

	const getCurrentUserProfile = async () =>
	{
		if (!user) return null;

		const url = `/api/social?username=${encodeURIComponent(user.username)}&token=${user.token}`;

		try
		{
			const { data } = await axios.get(url);
			setProfile(data.socialProfile);
		}
		catch (error)
		{
			captureException(error);
			return null;
		}
	};

	useEffect(() =>
	{
		getCurrentUserProfile();
	}, [user]);
	

	return (
		<SidePanelContainer>
			<UserInfo profile={profile} />
			<FollowButton profile={profile} setProfile={setProfile} />
			<SidebarNavigation userProfile={profile} />
		</SidePanelContainer>
	);
};

export default SidePanel;
