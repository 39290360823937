import React from "react";
import UserInfo from "./UserInfo";
import SidebarNavigation from "./SidebarNavigation";
import { useProfileContext } from "@client/Pages/Community/Profile/ProfileContext";
import FollowButton from "./FollowButton";
import SidePanelContainer from "./SidePanelContainer";

const SidePanel = () =>
{
	const { profile, setProfile } = useProfileContext();

	return (
		<SidePanelContainer>
			<UserInfo profile={profile} />
			<FollowButton profile={profile} setProfile={setProfile} />
			<SidebarNavigation userProfile={profile} />
		</SidePanelContainer>
	);
};

export default SidePanel;
