import LocalizedStrings from 'react-localization';

import { en, cn, de, fr, es, ja } from "@common/shared/i18n";

let s = new LocalizedStrings({
    English: en,
    French: fr,
    Spanish: es,
    German: de,
    Chinese: cn,
    Japanese: ja
});

export default s;
