import React from 'react';

const AlignModuleIcon = ({ width = 25, height = 25, color = "#CECECE" }) =>
{
    return (
        <svg width={width} height={height} viewBox="0 0 18 18" fill={color} xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.7">
                <path d="M8.78247 9.15996C8.78247 9.03491 8.83214 8.91499 8.92056 8.82657C9.00898 8.73815 9.12891 8.68848 9.25395 8.68848C9.37899 8.68848 9.49892 8.73815 9.58733 8.82657C9.67575 8.91499 9.72543 9.03491 9.72543 9.15996C9.72543 9.285 9.67575 9.40492 9.58733 9.49334C9.49892 9.58176 9.37899 9.63143 9.25395 9.63143C9.12891 9.63143 9.00898 9.58176 8.92056 9.49334C8.83214 9.40492 8.78247 9.285 8.78247 9.15996Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M9.25378 5.85938C8.37847 5.85938 7.53902 6.20709 6.92008 6.82603C6.30114 7.44496 5.95343 8.28442 5.95343 9.15972C5.95343 10.035 6.30114 10.8745 6.92008 11.4934C7.53902 12.1124 8.37847 12.4601 9.25378 12.4601C10.1291 12.4601 10.9685 12.1124 11.5875 11.4934C12.2064 10.8745 12.5541 10.035 12.5541 9.15972C12.5541 8.28442 12.2064 7.44496 11.5875 6.82603C10.9685 6.20709 10.1291 5.85938 9.25378 5.85938ZM9.25378 7.74529C8.87865 7.74529 8.51888 7.89431 8.25362 8.15957C7.98836 8.42483 7.83934 8.78459 7.83934 9.15972C7.83934 9.53486 7.98836 9.89462 8.25362 10.1599C8.51888 10.4251 8.87865 10.5742 9.25378 10.5742C9.62891 10.5742 9.98868 10.4251 10.2539 10.1599C10.5192 9.89462 10.6682 9.53486 10.6682 9.15972C10.6682 8.78459 10.5192 8.42483 10.2539 8.15957C9.98868 7.89431 9.62891 7.74529 9.25378 7.74529Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M9.25382 2.0874C7.37816 2.0874 5.57932 2.8325 4.25303 4.1588C2.92674 5.48509 2.18164 7.28392 2.18164 9.15958C2.18164 11.0352 2.92674 12.8341 4.25303 14.1604C5.57932 15.4867 7.37816 16.2318 9.25382 16.2318C11.1295 16.2318 12.9283 15.4867 14.2546 14.1604C15.5809 12.8341 16.326 11.0352 16.326 9.15958C16.326 7.28392 15.5809 5.48509 14.2546 4.1588C12.9283 2.8325 11.1295 2.0874 9.25382 2.0874ZM5.01051 9.15958C5.01051 8.03419 5.45757 6.95488 6.25335 6.15911C7.04912 5.36333 8.12842 4.91627 9.25382 4.91627C10.3792 4.91627 11.4585 5.36333 12.2543 6.15911C13.0501 6.95488 13.4971 8.03419 13.4971 9.15958C13.4971 10.285 13.0501 11.3643 12.2543 12.1601C11.4585 12.9558 10.3792 13.4029 9.25382 13.4029C8.12842 13.4029 7.04912 12.9558 6.25335 12.1601C5.45757 11.3643 5.01051 10.285 5.01051 9.15958Z" />
            </g>
        </svg>
    )
};

export default AlignModuleIcon