const commands = Object.freeze({
	GET_PROFILES: "get_profiles",
	GET_CONNECTION: "get_connection",
	GET_STATES: "get_states",
	GET_CAMERAS: "get_cameras",
	GET_MOUNTS: "get_mounts",
	GET_SCOPES: "get_scopes",
	GET_DSLR_LENSES: "get_dslr_lenses",
	GET_DOMES: "get_domes",
	GET_CAPS: "get_caps",
	GET_DRIVERS: "get_drivers",
	GET_FILTER_WHEELS: "get_filter_wheels",
	SET_BLOBS: "set_blobs",

	INVOKE_METHOD: "invoke_method",
	SET_PROPERTY: "set_property",
	GET_PROPERTY: "get_property",

	// #2 Commands from user to ekos
	CAPTURE_PREVIEW: "capture_preview",
	CAPTURE_TOGGLE_VIDEO: "capture_toggle_video",
	CAPTURE_TOGGLE_CAMERA: "capture_toggle_camera",
	CAPTURE_TOGGLE_FILTER_WHEEL: "capture_toggle_filter_wheel",
	CAPTURE_START: "capture_start",
	CAPTURE_STOP: "capture_stop",
	CAPTURE_GET_SEQUENCES: "capture_get_sequences",
	CAPTURE_CLEAR_SEQUENCES: "capture_clear_sequences",
	CAPTURE_SET_SETTINGS: "capture_set_settings",
	CAPTURE_ADD_SEQUENCE: "capture_add_sequence",
	CAPTURE_GET_CALIBRATION_SETTINGS: "capture_get_calibration_settings",
	CAPTURE_SET_FILE_SETTINGS: "capture_set_file_settings",
	CAPTURE_GET_FILE_SETTINGS: "capture_get_file_settings",
	CAPTURE_GET_PREVIEW_LABEL: "capture_get_preview_label",
	CAPTURE_GET_LIMITS: "capture_get_limits",
	CAPTURE_SET_LIMITS: "capture_set_limits",
	CAPTURE_SAVE_SEQUENCE_FILE: "capture_save_sequence_file",
	CAPTURE_LOAD_SEQUENCE_FILE: "capture_load_sequence_file",
	CAPTURE_REMOVE_SEQUENCE: "capture_remove_sequence",
	CAPTURE_LOOP: "capture_loop",
	CAPTURE_GENERATE_DARK_FLATS: "capture_generate_dark_flats",
	CAPTURE_SET_ALL_SETTINGS: "capture_set_all_settings",
	CAPTURE_GET_ALL_SETTINGS: "capture_get_all_settings",

	DARK_LIBRARY_START: "dark_library_start",
	DARK_LIBRARY_STOP: "dark_library_stop",
	DARK_LIBRARY_SET_CAMERA_PRESETS: "dark_library_set_camera_presets",
	DARK_LIBRARY_GET_CAMERA_PRESETS: "dark_library_get_camera_presets",
	DARK_LIBRARY_GET_DARK_SETTINGS: "dark_library_get_dark_settings",

	DARK_LIBRARY_SET_DEFECT_SETTINGS: "dark_library_set_defect_settings",
	DARK_LIBRARY_SET_DEFECT_PIXELS: "dark_library_set_defect_pixels",
	DARK_LIBRARY_GET_DEFECT_SETTINGS: "dark_library_get_defect_settings",
	DARK_LIBRARY_SAVE_MAP: "dark_library_save_map",
	DARK_LIBRARY_GET_VIEW_MASTERS: "dark_library_get_view_masters",
	DARK_LIBRARY_GET_MASTERS_IMAGE: "dark_library_get_masters_image",
	DARK_LIBRARY_CLEAR_MASTERS_ROW: "dark_library_clear_masters_row",
	DARK_LIBRARY_SET_DEFECT_FRAME: "dark_library_set_defect_frame",
	DARK_LIBRARY_SET_ALL_SETTINGS: "dark_library_set_all_settings",
	DARK_LIBRARY_GET_ALL_SETTINGS: "dark_library_get_all_settings",

	// File commands
	FILE_DEFAULT_PATH: "file_default_path",
	FILE_DIRECTORY_OPERATION: "file_directory_operation",

	MOUNT_PARK: "mount_park",
	MOUNT_UNPARK: "mount_unpark",
	MOUNT_ABORT: "mount_abort",
	MOUNT_SYNC_RADE: "mount_sync_rade",
	MOUNT_SYNC_TARGET: "mount_sync_target",
	MOUNT_GOTO_RADE: "mount_goto_rade",
	MOUNT_GOTO_TARGET: "mount_goto_target",
	MOUNT_SET_MOTION: "mount_set_motion",
	MOUNT_SET_TRACKING: "mount_set_tracking",
	MOUNT_SET_SLEW_RATE: "mount_set_slew_rate",
	MOUNT_SET_ALTITUDE_LIMITS: "mount_set_altitude_limits",
	MOUNT_SET_HA_LIMIT: "mount_set_ha_limit",
	MOUNT_SET_MERIDIAN_FLIP: "mount_set_meridian_flip",
	MOUNT_TOGGLE_AUTOPARK: "mount_toggle_autopark",
	MOUNT_SET_EVERYDAY_AUTO_PARK: "mount_set_everyday_auto_park",
	MOUNT_CLEAR: "mount_clear",
	MOUNT_GET_SETTINGS: "mount_get_settings",
	MOUNT_GOTO_PIXEL: "mount_goto_pixel",
	MOUNT_GET_ALL_SETTINGS: "mount_get_all_settings",
	MOUNT_SET_ALL_SETTINGS: "mount_set_all_settings",

	DOME_PARK: "dome_park",
	DOME_UNPARK: "dome_unpark",
	DOME_GOTO: "dome_goto",
	DOME_STOP: "dome_stop",

	CAP_PARK: "cap_park",
	CAP_UNPARK: "cap_unpark",
	CAP_SET_LIGHT: "cap_set_light",

	ADD_SCOPE: "scope_add",
	DELETE_SCOPE: "scope_delete",
	UPDATE_SCOPE: "scope_update",

	FOCUS_START: "focus_start",
	FOCUS_STOP: "focus_stop",
	FOCUS_RESET: "focus_reset",
	FOCUS_IN: "focus_in",
	FOCUS_OUT: "focus_out",
	FOCUS_LOOP: "focus_loop",
	FOCUS_CAPTURE: "focus_capture",
	FOCUS_SET_SETTINGS: "focus_set_settings",
	FOCUS_SET_CROSSHAIR: "focus_set_crosshair",
	FOCUS_SET_PRIMARY_SETTINGS: "focus_set_primary_settings",
	FOCUS_SET_PROCESS_SETTINGS: "focus_set_process_settings",
	FOCUS_SET_MECHANICS_SETTINGS: "focus_set_mechanics_settings",
	FOCUS_GET_PRIMARY_SETTINGS: "focus_get_primary_settings",
	FOCUS_GET_PROCESS_SETTINGS: "focus_get_process_settings",
	FOCUS_GET_MECHANICS_SETTINGS: "focus_get_mechanics_settings",
	FOCUS_SET_ALL_SETTINGS: "focus_set_all_settings",
	FOCUS_GET_ALL_SETTINGS: "focus_get_all_settings",

	GUIDE_START: "guide_start",
	GUIDE_STOP: "guide_stop",
	GUIDE_CLEAR: "guide_clear",
	GUIDE_SET_SETTINGS: "guide_set_settings",
	GUIDE_SET_CALIBRATION_SETTINGS: "guide_set_calibration_settings",
	GUIDE_REPORT: "guide_report",
	GUIDE_CAPTURE: "guide_capture",
	GUIDE_LOOP: "guide_loop",
	GUIDE_SET_ALL_SETTINGS: "guide_set_all_settings",
	GUIDE_GET_ALL_SETTINGS: "guide_get_all_settings",

	ALIGN_SOLVE: "align_solve",
	ALIGN_STOP: "align_stop",
	ALIGN_LOAD_AND_SLEW: "align_load_and_slew",
	ALIGN_SET_FILE_EXTENSION: "align_set_file_extension",
	ALIGN_SET_SETTINGS: "align_set_settings",
	ALIGN_SET_ASTROMETRY_SETTINGS: "align_set_astrometry_settings",
	ALIGN_MANUAL_ROTATOR_TOGGLE: "align_manual_rotator_toggle",
	ALIGN_MANUAL_ROTATOR_STATUS: "align_manual_rotator_status",
	ALIGN_SET_ALL_SETTINGS: "align_set_all_settings",
	ALIGN_GET_ALL_SETTINGS: "align_get_all_settings",

	SCHEDULER_SET_PRIMARY_SETTINGS: "scheduler_set_primary_settings",
	SCHEDULER_SET_JOB_STARTUP_CONDITIONS: "scheduler_set_job_startup_conditions",
	SCHEDULER_SET_JOB_CONSTRAINTS: "scheduler_set_job_constraints",
	SCHEDULER_SET_JOB_COMPLETION_SETTINGS:
		"scheduler_set_job_completion_settings",
	SCHEDULER_SET_OBSERVATORY_STARTUP_PROCEDURE:
		"scheduler_set_observatory_startup_procedure",
	SCHEDULER_SET_ABORTED_JOB_MANAGEMENT: "scheduler_set_aborted_job_management",
	SCHEDULER_SET_OBSERVATORY_SHUTDOWN_PROCEDURE:
		"scheduler_set_observatory_shutdown_procedure",
	SCHEDULER_GET_JOBS: "scheduler_get_jobs",
	SCHEDULER_ADD_JOBS: "scheduler_add_jobs",
	SCHEDULER_REMOVE_JOBS: "scheduler_remove_jobs",
	SCHEDULER_GET_SETTINGS: "scheduler_get_settings",
	SCHEDULER_UPDATE_SETTINGS: "scheduler_update_settings",
	SCHEDULER_START_JOB: "scheduler_start_job",
	SCHEDULER_IMPORT_MOSAIC: "scheduler_import_mosaic",
	SCHEDULER_UPDATE_JOB: "scheduler_update_job",
	SCHEDULER_SET_ALL_SETTINGS: "scheduler_set_all_settings",
	SCHEDULER_GET_ALL_SETTINGS: "scheduler_get_all_settings",
	SCHEDULER_LOAD_FILE: "scheduler_load_file",
	SCHEDULER_SAVE_SEQUENCE_FILE: "scheduler_save_sequence_file",
	SCHEDULER_SAVE_FILE: "scheduler_save_file",

	PAH_START: "polar_start",
	PAH_STOP: "polar_stop",
	PAH_REFRESH: "polar_refresh",
	PAH_SET_SETTINGS: "polar_set_settings",
	PAH_SET_CORRECTION_VECTOR: "polar_set_correction_vector",
	PAH_SET_CROSSHAIR: "polar_set_crosshair",
	PAH_SELECT_STAR_DONE: "polar_star_select_done",
	PAH_REFRESHING_DONE: "polar_refreshing_done",
	PAH_RESET_VIEW: "polar_reset_view",
	PAH_SLEW_DONE: "polar_slew_done",
	PAH_SET_ZOOM: "polar_set_zoom",
	PAH_SET_ALGORITHM: "polar_set_algorithm",

	OPTION_SET_HIGH_BANDWIDTH: "option_set_high_bandwidth",
	OPTION_SET_IMAGE_TRANSFER: "option_set_image_transfer",
	OPTION_SET_NOTIFICATIONS: "option_set_notifications",
	OPTION_SET_CLOUD_STORAGE: "option_set_cloud_storage",
	OPTION_SET: "option_set",
	OPTION_GET: "option_get",

	// #3 Commands from ekos to server
	NEW_CONNECTION_STATE: "new_connection_state",
	NEW_INDI_STATE: "new_indi_state",
	NEW_MOUNT_STATE: "new_mount_state",
	NEW_CAMERA_STATE: "new_camera_state",
	NEW_CAPTURE_STATE: "new_capture_state",
	NEW_FOCUS_STATE: "new_focus_state",
	NEW_GUIDE_STATE: "new_guide_state",
	NEW_ALIGN_STATE: "new_align_state",
	NEW_POLAR_STATE: "new_polar_state",
	NEW_DOME_STATE: "new_dome_state",
	NEW_CAP_STATE: "new_cap_state",
	NEW_VIDEO_FRAME: "new_video_frame",
	NEW_ALIGN_FRAME: "new_align_frame",
	NEW_NOTIFICATION: "new_notification",
	NEW_UNREAD_NOTIFICATION: "new_unread_notification",
	NEW_TEMPERATURE: "new_temperature",
	NEW_METADATA: "new_metadata",
	NEW_SCHEDULER_STATE: "new_scheduler_state",
	NEW_CLOUD_IMAGE: "new_cloud_image",
	
	SET_CLIENT_STATE: "SET_CLIENT_STATE",

	// #4 Commands from server to user
	SET_STATE: "set_state",
	LOGOUT: "logout",
	SESSION_EXPIRED: "session_expired",
	BANDWIDTH_WARNING: "bandwidth_warning",
	NEW_PREVIEW_IMAGE: "new_preview_image",
	NEW_PREVIEW_HISTOGRAM: "new_preview_histogram",
	SET_KSTARS_VERSION: "set_kstars_version",
	SET_KSTARS_MACHINE_ID: "set_kstars_machine_id",
	SET_ACTIVE_KSTARS_CONNECTION: "set_active_kstars_connection",
	DELETE_KSTAR_CONNECTION: "delete_kstar_connection",
	GET_CONNECTED_KSTARS: "get_connected_kstars",
	SET_CONNECTED_KSTARS: "set_connected_kstars",

	// #5 Commands from user to server
	GET_IMAGES: "get_images",
	DELETE_IMAGES: "delete_images",
	ADD_IMAGES: "add_images",
	ADD_TAG: "add_tag",
	REMOVE_TAG: "remove_tag",
	UPDATE_METADATA: "update_metadata",
	NEW_IMAGE_METADATA: "new_image_metadata",

	START_PROFILE: "profile_start",
	STOP_PROFILE: "profile_stop",
	ADD_PROFILE: "profile_add",
	GET_PROFILE: "profile_get",
	DELETE_PROFILE: "profile_delete",
	UPDATE_PROFILE: "profile_update",
	SET_PROFILE_MAPPING: "profile_set_mapping",
	SET_PROFILE_PORT_SELECTION: "profile_set_port_selection",
	GET_PROFILE_PORT_SELECTION: "profile_get_port_selection",

	DSLR_GET_INFO: "dslr_get_info",
	DSLR_SET_INFO: "dslr_set_info",
	DSLR_SET_MODE: "dslr_set_mode",

	GET_DEVICES: "get_devices",
	NEW_DEVICE_STATE: "new_device_state",
	DEVICE_GET_PROPERTY: "device_get_property",
	DEVICE_SET_PROPERTY: "device_set_property",
	DEVICE_GET: "device_get",
	DEVICE_RESTART: "device_restart",
	DEVICE_BLOB_GET: "device_blob_get",
	DEVICE_PROPERTY_GET: "device_property_get",
	DEVICE_PROPERTY_SET: "device_property_set",
	DEVICE_PROPERTY_ADD: "device_property_add",
	DEVICE_PROPERTY_REMOVE: "device_property_remove",
	DEVICE_PROPERTY_SUBSCRIBE: "device_property_subscribe",
	DEVICE_PROPERTY_UNSUBSCRIBE: "device_property_unsubscribe",
	DEVICE_PROPERTY_UPDATE_TS: "device_property_update_ts",
	DEVICE_MESSAGE: "device_message",

	DIALOG_GET_INFO: "dialog_get_info",
	DIALOG_GET_RESPONSE: "dialog_get_response",

	LIVESTACK_START: "livestack_start",
	LIVESTACK_STOP: "livestack_stop",
	LIVESTACK_SET_OPTIONS: "livestack_set_options",

	FM_GET_DATA: "fm_get_data",
	FM_SET_DATA: "fm_set_data",

	GET_STELLARSOLVER_PROFILES: "get_stellarsolver_profiles",

	ASTRO_GET_ALMANAC: "astro_get_almanac",
	ASTRO_SEARCH_OBJECTS: "astro_search_objects",
	ASTRO_GET_OBJECT_INFO: "astro_get_object_info",
	ASTRO_GET_OBJECTS_INFO: "astro_get_objects_info",
	ASTRO_GET_OBJECTS_IMAGE: "astro_get_objects_image",
	ASTRO_GET_OBJECTS_OBSERVABILITY: "astro_get_objects_observability",
	ASTRO_GET_OBJECTS_RISESET: "astro_get_objects_riseset",
	ASTRO_GET_NAMES: "astro_get_names",
	ASTRO_GET_LOCATION: "astro_get_location",
	ASTRO_GET_DESIGNATIONS: "astro_get_designations",

	DSLR_ADD_LENS: "dslr_add_lens",
	DSLR_DELETE_LENS: "dslr_delete_lens",
	DSLR_UPDATE_LENS: "dslr_update_lens",

	SAVE_NOTIFICATION_TOKEN: "save_notification_token",
	SEND_NOTIFICATION_TOKEN: "send_notification_token",
	SAVE_USER_PLAN_ID: "save_user_plan_id",

	// Optical Trains
	OPTICAL_TRAIN_GET_NAMES: "optical_train_get_names",
	OPTICAL_TRAIN_GET_INFO: "optical_train_get_info",
	OPTICAL_TRAIN_REMOVE_INFO: "optical_train_remove_info",
	TRAIN_GET_ALL: "train_get_all",
	TRAIN_UPDATE: "train_update",
	TRAIN_SET: "train_set",
	TRAIN_ADD: "train_add",
	TRAIN_DELETE: "train_delete",
	TRAIN_CONFIGURATION_REQUESTED: "train_configuration_requested",
	TRAIN_ACCEPT: "train_accept",
	TRAIN_GET_PROFILES: "train_get_profiles",
	TRAIN_SETTINGS_GET: "train_settings_get",
	TRAIN_RESET: "train_reset",
});

module.exports = commands;
