import React from 'react'

const IndiModuleIcon = ({ width = 25, height = 25, color = "#CECECE" }) =>
{
    return (
        <svg width={width} height={height} viewBox="0 0 14 14" fill={color} xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.7" clipPath="url(#clip0_1278_6167)">
                <path d="M3.15852 1.73222C3.15852 1.78009 3.18246 2.07792 3.21171 2.39436C3.24096 2.71081 3.29946 3.38358 3.34467 3.88883C3.38721 4.39408 3.43774 4.93123 3.45103 5.08547C3.46433 5.2397 3.50688 5.70506 3.54411 6.12255C3.58134 6.54004 3.74886 8.42807 3.91639 10.3214C4.08392 12.2121 4.2222 13.773 4.2222 13.7837C4.2222 13.797 5.49595 13.8076 7.09413 13.8076C8.84122 13.8076 9.96606 13.797 9.96606 13.7837C9.96606 13.7704 10.0432 12.8875 10.1389 11.8212C10.232 10.7575 10.4607 8.1914 10.6415 6.12255C10.825 4.0537 10.9819 2.30395 10.9898 2.23747L11.0031 2.11515L10.7319 2.09919C10.3889 2.08058 10.1788 2.03537 9.72407 1.8838C9.20287 1.70829 9.05129 1.6817 8.54339 1.66574C7.97166 1.64713 7.68979 1.68702 7.14199 1.86518C6.53038 2.06462 6.44263 2.08324 5.92408 2.09919C5.34172 2.11781 5.06251 2.07792 4.50408 1.89443C3.96958 1.71893 3.81268 1.68436 3.45901 1.66574L3.15852 1.64713V1.73222Z" />
                <path d="M3.02822 0.264082C3.02822 0.306629 3.05481 0.631051 3.08672 0.990042L3.14522 1.64154L3.42444 1.66016C3.8047 1.68409 3.95096 1.716 4.46684 1.88353C5.07314 2.07765 5.33374 2.11754 5.92408 2.09893C6.44262 2.08297 6.53037 2.06436 7.14199 1.86492C7.68978 1.68675 7.97166 1.64686 8.54338 1.66548C9.05129 1.68143 9.20286 1.70802 9.72407 1.88353C9.9235 1.95001 10.1708 2.02181 10.2719 2.04308C10.5059 2.09361 11.0031 2.12552 11.0031 2.08829C11.0031 2.07499 11.0377 1.66016 11.0829 1.16821C11.1255 0.673598 11.1627 0.253445 11.1627 0.232172C11.1627 0.197602 10.684 0.192284 7.09412 0.192284H3.02556L3.02822 0.264082Z" />
            </g>
            <defs>
                <clipPath id="clip0_1278_6167">
                    <rect width="13.6151" height="13.6151" fill="white" transform="translate(0.286591 0.192383)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IndiModuleIcon