export const viewerConstants = {
    SHOW_FILTER: 'showFilter',
    SORT_FILTER: 'sortFilter',
    SORT_DIRECTION: 'sortDirection',
    MAX_IMAGES: 'maxImages',
    CURRENT_PAGE: 'currentPage',
    SHOW_BACK: 'showBack',
    SHOW_FORWARD: 'showForward',

    ADD_RULE: 'add_rule',
    REMOVE_RULE: 'remove_rule',

    SET_DB_RULES: 'set_db_rules',

    ADD_TAG: 'add_tag',
    REMOVE_TAG: 'remove_tag',
};
