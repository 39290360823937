import api from "@common/shared/utils/api";
import { logError } from "@common/shared/utils/logs";
import { get, isEmpty } from "lodash";

/**
 * Used for Sync settings, Currently we support Presets, Achievements, TargetLists, FOV Profiles
 * @param {*} type preset, fov etc.. 
 * @param {*} data Array of objects: [{name: "foo", uuid: "foo", createdAt: timestamp, deletedAt: timestamp},
 *  {name: "foo1", uuid: "foo1", createdAt: timestamp, deletedAt: timestamp},] 
 * @param {*} username current username 
 * @param {*} token user token
 * @param {*} updaterAction An action to dispatch to redux
 * @param {*} URL Ekoslive URL
 */
const SyncSettings = async ({
	type,
	data = [],
	username,
	token,
	updaterAction,
	URL
}) =>
{
	try
	{
		const url =
			URL +
			`/api/sync?username=${username}&token=${token}`;

		const res = await api.ekoslivePost(url, {
			type,
			data,
		});
		const array = get(res.data, "payload", []);
		if (!isEmpty(array)) updaterAction(array);
	}
	catch (error)
	{
		logError(`SyncSettings error: ${error}`);
	}
};

export default SyncSettings;