import s from "../i18n/i18n";
import { achievementsConstants } from "../redux/constants";

const achievementsInfo = [
	{
		type: achievementsConstants.CAPTURE_PREVIEW_ACHIEVED,
		title: s.achievements.capture_preview_title,
		description: s.achievements.capture_preview_description,
		requirement: null,
		points: 10,
		reward: "",
		key: "capture_preview",
	},
	{
		type: achievementsConstants.TEN_SEQUENCES_ACHIEVED,
		title: s.achievements.ten_sequences_title,
		description: s.achievements.ten_sequences_description,
		requirement: null,
		points: 10,
		reward: "",
		key: "ten_sequences",
	},
	{
		type: achievementsConstants.MOUNT_GOTO_ACHIEVED,
		title: s.achievements.mount_goto_title,
		description: s.achievements.mount_goto__description,
		requirement: null,
		points: 10,
		reward: "",
		key: "mount_goto",
	},
	{
		type: achievementsConstants.VIDEO_RECORDING_ACHIEVED,
		title: s.achievements.video_recording_title,
		description: s.achievements.video_recording_description,
		requirement: null,
		points: 10,
		reward: "",
		key: "video_recording",
	},
	{
		type: achievementsConstants.WEATHER_CHECK_ACHIEVED,
		title: s.achievements.weather_check_title,
		description: s.achievements.weather_check__description,
		requirement: null,
		points: 5,
		reward: "",
		key: "weather_check",
	},
	{
		type: achievementsConstants.LIVE_STACK_ACHIEVED,
		title: s.achievements.live_stacking_title,
		description: s.achievements.live_stacking_description,
		requirement: null,
		points: 15,
		reward: "",
		key: "live_stacking",
	},
	{
		type: achievementsConstants.CREATE_DARKS_ACHIEVED,
		title: s.achievements.create_darks_title,
		description: s.achievements.create_darks_description,
		requirement: null,
		points: 10,
		reward: "",
		key: "create_darks",
	},
	{
		type: achievementsConstants.CREATE_DEFECT_ACHIEVED,
		title: s.achievements.create_defect_title,
		description: s.achievements.create_defect_description,
		requirement: null,
		points: 10,
		reward: "",
		key: "create_defects",
	},
	{
		type: achievementsConstants.IMPORT_MOSAIC_ACHIEVED,
		title: s.achievements.import_mosaic_title,
		description: s.achievements.import_mosaic_description,
		requirement: null,
		points: 15,
		reward: "",
		key: "import_mosaic",
	},
	{
		type: achievementsConstants.CAPTURE_SKY_MASTER,
		title: s.achievements.capture_master_title,
		description: s.achievements.capture_master_description,
		requirement: null,
		points: 50,
		reward: "",
		key: "capture_master",
	},
	{
		type: achievementsConstants.CAPTURE_SKY_LEGEND,
		title: s.achievements.capture_legend_title,
		description: s.achievements.capture_legend_description,
		requirement: null,
		points: 100,
		reward: "",
		key: "capture_legend",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: s.achievements.all_messier_title,
		description: s.achievements.all_messier_description,
		requirement: null,
		points: 1000,
		reward: "",
		key: "all_messiers",
	},
	{
		type: achievementsConstants.POLAR_ALIGNMENT_BOX,
		title: s.achievements.paa_title,
		description: s.achievements.paa_description,
		requirement: null,
		points: 30,
		reward: "",
		key: "paa_arcsec",
	},
	{
		type: achievementsConstants.ACHIEVE_GUIDE_RMS,
		title: s.achievements.guide_rms_title,
		description: s.achievements.guide_rms_description,
		requirement: null,
		points: 30,
		reward: "",
		key: "guide_rms",
	},
	{
		type: achievementsConstants.FILTER_CAPTURE_IMAGE,
		title: "Wave Splitter",
		description: s.achievements.filtered_image_description,
		requirement: null,
		points: 10,
		reward: "",
		key: "filtered_image",
	},
	{
		type: achievementsConstants.DOWNLOAD_GALLERY_IMAGE,
		title: s.achievements.file_stored,
		description: s.achievements.gallery_image_description,
		requirement: null,
		points: 5,
		reward: "",
		key: "gallery_image",
	},
	{
		type: achievementsConstants.EXPLORER,
		title: s.achievements.complete_tour_guide,
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "explorer",
	},

	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 1 (Crab Nebula)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m1",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 2 (Globular Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m2",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 3 (Globular Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m3",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 4 (Globular Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m4",
	},

	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 5 (Globular Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m5",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 6 (Open Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m6",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 7 (Open Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m7",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 8 (Lagoon Nebula)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m8",
	},

	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 9 (Globular Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m9",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 10 (Globular Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m10",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 11 (Wild Duck Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m11",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 12 (Globular Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m12",
	},

	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 13 (Hercules Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m13",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 14 (Globular Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m14",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 15 (Globular Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m15",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 16 (Eagle Nebula)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m16",
	},

	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 17 (Omega Nebula)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m17",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 18 (Open Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m18",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 19 (Globular Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m19",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 20 (Trifid Nebula)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m20",
	},

	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 21 (Open Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m21",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 22 (Globular Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m22",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 23 (Open Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m23",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 24 (Open Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m24",
	},

	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 25 (Open Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m25",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 26 (Open Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m26",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 27 (Dumbbell Nebula)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m27",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 28 (Globular Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m28",
	},

	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 29 (Open Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m29",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 30 (Globular Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m30",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 31 (Andromeda Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m31",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 32 (Dwarf Elliptical Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m32",
	},

	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 33 (Triangulum Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m33",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 34 (Open Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m34",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 35 (Open Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m35",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 36 (Open Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m36",
	},

	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 37 (Open Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m37",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 38 (Open Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m38",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 39 (Open Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m39",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 40 (Winnecke 4)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m40",
	},

	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 41 (Open Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m41",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 42 (Orion Nebula)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m42",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 43 (De Mairan's Nebula)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m43",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 44 (Beehive Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m44",
	},

	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 45 (Pleiades)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m45",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 46 (Open Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m46",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 47 (Open Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m47",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 48 (Open Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m48",
	},

	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 49 (Elliptical Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m49",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 50 (Open Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m50",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 51 (Whirlpool Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m51",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 52 (Open Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m52",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 53 (Globular Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m53",
	},

	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 54 (Globular Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m54",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 55 (Globular Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m55",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 56 (Globular Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m56",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 57 (Ring Nebula)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m57",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 58 (Barred Spiral Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m58",
	},

	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 59 (Elliptical Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m59",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 60 (Elliptical Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m60",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 61 (Spiral Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m61",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 62 (Globular Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m62",
	},

	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 63 (Sunflower Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m63",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 64 (Blackeye Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m64",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 65 (Spiral Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m65",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 66 (Spiral Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m66",
	},

	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 67 (Open Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m67",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 68 (Globular Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m68",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 69 (Globular Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m69",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 70 (Globular Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m70",
	},

	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 71 (Globular Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m71",
	},

	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 72 ((Globular Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m72",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 73 (Asterism)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m73",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 74 (Spiral Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m74",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 75 (Globular Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m75",
	},

	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 76 (Little Dumbbell Nebula)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m76",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 77 (Spiral Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m77",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 78 (Reflection Nebula)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m78",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 79 (Globular Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m79",
	},

	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 80 (Globular Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m80",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 81 (Bode's Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m81",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 82 (Cigar Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m82",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 83 (Southern Pinwheel Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m83",
	},

	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 84 (Elliptical Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m84",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 85 (Lenticular Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m85",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 86 (Elliptical Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m86",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 87 (Giant Elliptical Galax)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m87",
	},

	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 88 (Spiral Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m88",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 89 (Elliptical Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m89",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 90 (Spiral Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m90",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 91 (Spiral Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m91",
	},

	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 92 (Globular Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m92",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 93 (Open Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m93",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 94 (Spiral Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m94",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 95 (Lenticular Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m95",
	},

	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 96 (Spiral Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m96",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 97 (Owl Nebula)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m97",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 98 (Spiral Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m98",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 99 (Spiral Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m99",
	},

	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 100 (Mirror Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m100",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 101 (Pinwheel Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m101",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 102 (Spindle Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m102",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 103 (Open Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m103",
	},

	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 104 (Sombrero Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m104",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 105 (Elliptical Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m105",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 106 (Spiral Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m106",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 107 (Globular Cluster)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m107",
	},

	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 108 (Surfboard Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m108",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 109 (Spiral Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m109",
	},
	{
		type: achievementsConstants.ALL_MESSIER_CAPTURED,
		title: "M 110 (Dwarf Galaxy)",
		description: s.achievements.explorer,
		requirement: null,
		points: 5,
		reward: "",
		key: "m110",
	},
];

export default achievementsInfo;
