import React from "react";

const EkosLiveLogo = ({ width = 359, height = 247, className = "" }) =>
{
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 359 247"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M328.733 59.5394C394.617 6.41133 294.214 -17.3765 140.945 75.8241C146.665 70.4379 156.59 63.307 168.829 55.6191C160.373 54.8196 152.156 55.2111 143.649 57.2247C133.342 59.6641 123.613 64.0369 115.042 70.0813C106.472 76.1256 99.2371 83.7171 93.7713 92.4011C88.3054 101.085 84.721 110.683 83.2327 120.62C81.7444 130.556 82.3829 140.627 85.1098 150.23C87.8367 159.832 92.596 168.769 99.1026 176.503C104.465 182.878 113.552 190.016 121.759 194.922C149.405 184.282 182.423 166.809 218.487 140.969C203.939 156.898 154.678 190.403 118.492 206.186C68.2187 228.114 -8.57288 226.907 54.4073 167.763C22.7836 197.46 39.8933 214.713 85.0162 205.871C80.3817 202.431 76.6708 198.513 71.6246 192.514C62.9094 182.154 56.5345 170.185 52.882 157.323C49.2294 144.461 48.3743 130.971 50.3678 117.661C52.3613 104.351 57.1624 91.4957 64.4836 79.8639C71.8048 68.2322 81.4954 58.0638 92.975 49.9677C104.455 41.8716 117.487 36.0145 131.292 32.747C145.096 29.4796 159.389 28.8691 173.314 30.9522C183.983 32.5481 194.179 36.6483 198.364 38.4788C218.632 27.5515 240.717 17.2092 259.562 10.6293C318.271 -9.86988 404.984 -1.94847 328.733 59.5394Z"
				fill="#FF6600"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M216.645 70.402C184.884 82.3831 146.698 102.418 104.905 132.362C121.399 114.302 177.25 76.3155 218.276 58.4206C275.275 33.559 362.339 34.9286 290.934 101.984C328.751 66.4705 305.1 46.6536 247.548 60.693C250.288 63.6029 259.617 73.7651 264.112 81.8708C272.217 96.4861 274.117 108.83 275.044 123.245C275.972 137.66 274.09 152.121 269.511 165.763C264.931 179.404 257.749 191.945 248.394 202.634C239.039 213.323 227.704 221.94 215.068 227.97C202.432 233.999 188.754 237.317 174.854 237.724C160.954 238.132 147.118 235.62 134.175 230.34L127.177 226.981C112.946 232.723 98.4823 237.767 85.7745 241.079C33.3392 254.742 -41.4146 241.747 29.1472 193.721C-31.8207 235.218 53.7032 262.958 193.393 192.821C187.559 197.483 177.235 203.596 164.659 209.993L167.64 210.528C178.017 210.224 188.228 207.747 197.662 203.245C207.096 198.744 215.559 192.31 222.543 184.33C229.527 176.35 234.889 166.987 238.308 156.803C241.727 146.618 243.132 135.822 242.439 125.06C241.747 114.298 240.206 105.149 234.277 94.171C229.641 85.5861 222.585 74.8471 216.645 70.402Z"
				fill="#E2E2E2"
			/>
		</svg>
	);
};

export default EkosLiveLogo;
