export const URLS = [
	{
		name: "About",
		path: "/#about",
	},
	{
		name: "Features",
		path: "/features",
	},
	{
		name: "Pricing",
		path: "/pricing",
	},
];
