export const achievementsConstants = {
    RESET_ACHIEVEMENTS: "RESET_ACHIEVEMENTS",
    CAPTURE_PREVIEW_ACHIEVED: "CAPTURE_PREVIEW_ACHIEVED",
    TEN_SEQUENCES_ACHIEVED: "TEN_SEQUENCES_ACHIEVED",
    MOUNT_GOTO_ACHIEVED: "MOUNT_GOTO_ACHIEVED",
    SET_ACHIEVEMENT: "SET_ACHIEVEMENT",
    SET_ACHIEVEMENTS: "SET_ACHIEVEMENTS",
    VIDEO_RECORDING_ACHIEVED: "VIDEO_RECORDING_ACHIEVED",
    WEATHER_CHECK_ACHIEVED: "WEATHER_CHECK_ACHIEVED",
    LIVE_STACK_ACHIEVED: "LIVE_STACK_ACHIEVED",
    IMPORT_MOSAIC_ACHIEVED: "IMPORT_MOSAIC_ACHIEVED",
    CREATE_DARKS_ACHIEVED: "CREATE_DARKS_ACHIEVED",
    CREATE_DEFECT_ACHIEVED: "CREATE_DEFECT_ACHIEVED",
    CAPTURE_MESSIER_ACHIEVED: "CAPTURE_MESSIER_ACHIEVED",
    ALL_MESSIER_CAPTURED: "ALL_MESSIER_CAPTURED",
    TOTAL_MESSIER_CAPTURED: "TOTAL_MESSIER_CAPTURED",
    TOTAL_IMAGES_CAPTURED: "TOTAL_IMAGES_CAPTURED",
    CAPTURE_SKY_MASTER: "CAPTURE_SKY_MASTER",
    CAPTURE_SKY_LEGEND: "CAPTURE_SKY_LEGEND",
    POLAR_ALIGNMENT_BOX: "POLAR_ALIGNMENT_BOX",
    ACHIEVE_GUIDE_RMS: "ACHIEVE_GUIDE_RMS",
    FILTER_CAPTURE_IMAGE: "FILTER_CAPTURE_IMAGE",

    DOWNLOAD_GALLERY_IMAGE: "DOWNLOAD_GALLERY_IMAGE",

    EXPLORER: "EXPLORER",
};
